import React, { createContext, useState } from "react";

export const DeviceContext = createContext();

export const DeviceProvider = ({ children }) => {
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
    const [selectedIssue, setSelectedIssue] = useState("");

  return (
    <DeviceContext.Provider
      value={{
        selectedBrand,
        setSelectedBrand,
        selectedModel,
        setSelectedModel,
        selectedIssue,
        setSelectedIssue
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};
