// FacebookPixel.js

import { useEffect } from "react";

const FacebookPixel = () => {
  useEffect(() => {
    // Define the fbq function inside the useEffect to avoid 'no-undef' error
    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = true;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = true;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

    // Initialize the fbq function and track the 'PageView' event
    if (window.fbq) {
      window.fbq("init", "3835993949773706"); // Replace with your actual Pixel ID
      window.fbq("track", "PageView");
    }
  }, []);

  return null;
};

export default FacebookPixel;
