import React, { lazy, Suspense, useState } from "react";
import "./App.css"
import { BrowserRouter, Routes, Route} from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";
import { DeviceProvider } from "./Components/DeviceContext";

import FacebookPixel from "./Components/FacebookPixel";
import SEO from "./Components/SEO";

const Home = lazy(() => import("./Components/Home"));
const ContactUs = lazy(() => import("./Components/ContactUs"));
const AboutUs = lazy(() => import("./Components/AboutUs"));
const Help = lazy(() => import("./Components/Help"));
const Kolkata = lazy(() => import("./Components/Kolkata"));
const PartnerWithUs = lazy(() => import("./Components/PartnerWithUs"));

// EarBuds
const EarBuds = lazy(() => import("./Components/EarBuds/Brand"));
const Model = lazy(() => import("./Components/EarBuds/Model"))
const Issue = lazy(() => import("./Components/EarBuds/Issues"))
const PriceDetails = lazy(() => import("./Components/EarBuds/PriceDetails"))

// Laptop
const Laptop = lazy(() => import("./Components/Laptop/Brand"));
const ModelLaptop = lazy(() => import("./Components/Laptop/Model"))
const IssueLaptop = lazy(() => import("./Components/Laptop/Issues"))
const PriceDetailsLaptop = lazy(() => import("./Components/Laptop/PriceDetails"))

// Mobile
const Mobile = lazy(() => import("./Components/Mobile/Brand"));
const ModelMobile = lazy(() => import("./Components/Mobile/Model"))
const IssueMobile = lazy(() => import("./Components/Mobile/Issues"))
const PriceDetailsMobile = lazy(() => import("./Components/Mobile/PriceDetails"))

// SmartWatch
const SmartWatch = lazy(() => import("./Components/SmartWatch/Brand"));
const ModelWatch = lazy(() => import("./Components/SmartWatch/Model"))
const IssueWatch = lazy(() => import("./Components/SmartWatch/Issues"))
const PriceDetailsWatch = lazy(() => import("./Components/SmartWatch/PriceDetails"))

// Tablet
const Tablet = lazy(() => import("./Components/Tablet/Brand"));
const ModelTablet = lazy(() => import("./Components/Tablet/Model"))
const IssueTablet = lazy(() => import("./Components/Tablet/Issues"))
const PriceDetailsTablet = lazy(() => import("./Components/Tablet/PriceDetails"))

// Appliances
const SelectAppliances = lazy(() => import("./Components/Appliances/SelectAppliances"));
const SelectBrand = lazy(() => import("./Components/Appliances/Brand"));
const SelectIssues = lazy(() => import("./Components/Appliances/Issues"));
const PriceDetailsAppliances = lazy(() => import("./Components/Appliances/PriceDetails"));

function App() {

  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedIssue, setSelectedIssue] = useState("");

  return (
    <>

    <SEO />
    <FacebookPixel />
    
    <DeviceProvider>

    <BrowserRouter>
    <ScrollToTop />
    <Suspense fallback={
      <div className="spinnerWrapper">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    }>
    <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/imac" element={<IMac />} /> */}
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/help" element={<Help />} />
        <Route path="/kolkata" element={<Kolkata />} />
        <Route path="/partner-with-us" element={<PartnerWithUs />} />

        {/* Appliances */}
        <Route path="/select-appliances" element={<SelectAppliances />} />
        <Route path="/select-brand/:applianceType" element={<SelectBrand />} />
        <Route path="/select-issues/:applianceType/:brandName" element={<SelectIssues />} />
        <Route path="/price-details/:applianceType/:brandName/:issue" element={<PriceDetailsAppliances />} />

        {/* Earbuds */}
        <Route path="/earbuds/select-brand" element={<EarBuds/>} />

        {/* Apple */}
        <Route path="/earbuds/apple/select-Model" element={<Model />} />

        {/* Others */}
        <Route path="/airpods-others/select-issue" element={<Issue />} />
        <Route path="/airpods-others/battery-replacement/price-details" element={<PriceDetails />} />
        <Route path="/airpods-others/case-charging/price-details" element={<PriceDetails />} />
        <Route path="/airpods-others/buds-charging/price-details" element={<PriceDetails />} />
        <Route path="/airpods-others/speaker-issue/price-details" element={<PriceDetails />} />
        <Route path="/airpods-others/water-damage/price-details" element={<PriceDetails />} />
        <Route path="/airpods-others/rl-buds/price-details" element={<PriceDetails />} />
        <Route path="/airpods-others/other-issue/price-details" element={<PriceDetails />} />

        {/* AirPods 1st gen */}
        <Route path="/airpods(1stgen)/select-issue" element={<Issue />} />
        <Route path="/airpods(1stgen)/battery-replacement/price-details" element={<PriceDetails />} />
        <Route path="/airpods(1stgen)/case-charging/price-details" element={<PriceDetails />} />
        <Route path="/airpods(1stgen)/buds-charging/price-details" element={<PriceDetails />} />
        <Route path="/airpods(1stgen)/speaker-issue/price-details" element={<PriceDetails />} />
        <Route path="/airpods(1stgen)/water-damage/price-details" element={<PriceDetails />} />
        <Route path="/airpods(1stgen)/rl-buds/price-details" element={<PriceDetails />} />
        
        {/* AirPods 2nd gen */}
        <Route path="/airpods(2ndgen)/select-issue" element={<Issue />} />
        <Route path="/airpods(2ndgen)/battery-replacement/price-details" element={<PriceDetails />} />
        <Route path="/airpods(2ndgen)/case-charging/price-details" element={<PriceDetails />} />
        <Route path="/airpods(2ndgen)/buds-charging/price-details" element={<PriceDetails />} />
        <Route path="/airpods(2ndgen)/speaker-issue/price-details" element={<PriceDetails />} />
        <Route path="/airpods(2ndgen)/water-damage/price-details" element={<PriceDetails />} />
        <Route path="/airpods(2ndgen)/rl-buds/price-details" element={<PriceDetails />} />

        {/* Airpods 3rd gen */}
        <Route path="/airpods(3rdgen)/select-issue" element={<Issue selectedModel={selectedModel} setSelectedBrand={setSelectedBrand} setSelectedModel={setSelectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/airpods(3rdgen)/battery-replacement/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/airpods(3rdgen)/case-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/airpods(3rdgen)/buds-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/airpods(3rdgen)/speaker-issue/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/airpods(3rdgen)/water-damage/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/airpods(3rdgen)/rl-buds/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Airpods Pro 1st gen */}
        <Route path="/airpods-Pro-(1stgen)/select-issue" element={<Issue selectedModel={selectedModel} setSelectedBrand={setSelectedBrand} setSelectedModel={setSelectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/airpods-Pro-(1stgen)/battery-replacement/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/airpods-Pro-(1stgen)/case-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/airpods-Pro-(1stgen)/buds-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/airpods-Pro-(1stgen)/speaker-issue/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/airpods-Pro-(1stgen)/water-damage/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/airpods-Pro-(1stgen)/rl-buds/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Airpods Pro 2nd gen */}
        <Route path="/airpods-Pro-(2ndgen)/select-issue" element={<Issue selectedModel={selectedModel} setSelectedBrand={setSelectedBrand} setSelectedModel={setSelectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/airpods-Pro-(2ndgen)/battery-replacement/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/airpods-Pro-(2ndgen)/case-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/airpods-Pro-(2ndgen)/buds-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/airpods-Pro-(2ndgen)/speaker-issue/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/airpods-Pro-(2ndgen)/water-damage/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/airpods-Pro-(2ndgen)/rl-buds/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Airpods Max */}
        <Route path="/airpods-max/select-issue" element={<Issue selectedModel={selectedModel} setSelectedBrand={setSelectedBrand} setSelectedModel={setSelectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/airpods-Max/battery-replacement/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/airpods-Max/case-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/airpods-Max/buds-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/airpods-Max/speaker-issue/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/airpods-Max/water-damage/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/airpods-Max/rl-buds/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* OnePlus */}
        <Route path="/earbuds/oneplus/select-Model" element={<Model selectedBrand={selectedBrand} setSelectedBrand={setSelectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/oneplus-earbuds-others/select-issue" element={<Issue />} />
        <Route path="/oneplus-earbuds-others/battery-replacement/price-details" element={<PriceDetails />} />
        <Route path="/oneplus-earbuds-others/case-charging/price-details" element={<PriceDetails />} />
        <Route path="/oneplus-earbuds-others/buds-charging/price-details" element={<PriceDetails />} />
        <Route path="/oneplus-earbuds-others/speaker-issue/price-details" element={<PriceDetails />} />
        <Route path="/oneplus-earbuds-others/water-damage/price-details" element={<PriceDetails />} />
        <Route path="/oneplus-earbuds-others/rl-buds/price-details" element={<PriceDetails />} />
        <Route path="/oneplus-earbuds-others/other-issue/price-details" element={<PriceDetails />} />

        {/* OnePlus Buds 3 */}
        <Route path="/Oneplus-Buds-3/select-issue" element={<Issue selectedModel={selectedModel} setSelectedBrand={setSelectedBrand} setSelectedModel={setSelectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/Oneplus-Buds-3/battery-replacement/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Buds-3/case-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Buds-3/buds-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Buds-3/speaker-issue/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Buds-3/water-damage/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Buds-3/rl-buds/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Buds Pro */}
        <Route path="/Oneplus-Buds-Pro/select-issue" element={<Issue selectedModel={selectedModel} setSelectedBrand={setSelectedBrand} setSelectedModel={setSelectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/Oneplus-Buds-Pro/battery-replacement/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Buds-Pro/case-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Buds-Pro/buds-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Buds-Pro/speaker-issue/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Buds-Pro/water-damage/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Buds-Pro/rl-buds/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Buds */}
        <Route path="/Oneplus-Buds/select-issue" element={<Issue selectedModel={selectedModel} setSelectedBrand={setSelectedBrand} setSelectedModel={setSelectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/Oneplus-Buds/battery-replacement/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Buds/case-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Buds/buds-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Buds/speaker-issue/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Buds/water-damage/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Buds/rl-buds/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Buds Z2 */}
        <Route path="/Oneplus-Buds-Z2/select-issue" element={<Issue selectedModel={selectedModel} setSelectedBrand={setSelectedBrand} setSelectedModel={setSelectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/Oneplus-Buds-Z2/battery-replacement/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Buds-Z2/case-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Buds-Z2/buds-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Buds-Z2/speaker-issue/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Buds-Z2/water-damage/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Buds-Z2/rl-buds/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Nord Buds */}
        <Route path="/Oneplus-Nord-Buds/select-issue" element={<Issue selectedModel={selectedModel} setSelectedBrand={setSelectedBrand} setSelectedModel={setSelectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/Oneplus-Nord-Buds/battery-replacement/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Nord-Buds/case-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Nord-Buds/buds-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Nord-Buds/speaker-issue/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Nord-Buds/water-damage/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Oneplus-Nord-Buds/rl-buds/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Samsung */}
        <Route path="/earbuds/samsung/select-Model" element={<Model selectedBrand={selectedBrand} setSelectedBrand={setSelectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/samsung-earbuds-others/select-issue" element={<Issue />} />
        <Route path="/samsung-earbuds-others/battery-replacement/price-details" element={<PriceDetails />} />
        <Route path="/samsung-earbuds-others/case-charging/price-details" element={<PriceDetails />} />
        <Route path="/samsung-earbuds-others/buds-charging/price-details" element={<PriceDetails />} />
        <Route path="/samsung-earbuds-others/speaker-issue/price-details" element={<PriceDetails />} />
        <Route path="/samsung-earbuds-others/water-damage/price-details" element={<PriceDetails />} />
        <Route path="/samsung-earbuds-others/rl-buds/price-details" element={<PriceDetails />} />
        <Route path="/samsung-earbuds-others/other-issue/price-details" element={<PriceDetails />} />

        {/* Galaxy Buds+ */}
        <Route path="/Galaxy-Buds+/select-issue" element={<Issue selectedModel={selectedModel} setSelectedBrand={setSelectedBrand} setSelectedModel={setSelectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/Galaxy-Buds+/battery-replacement/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds+/case-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds+/buds-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds+/speaker-issue/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds+/water-damage/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds+/rl-buds/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Galaxy Buds 2 */}
        <Route path="/Galaxy-Buds-2/select-issue" element={<Issue selectedModel={selectedModel} setSelectedBrand={setSelectedBrand} setSelectedModel={setSelectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/Galaxy-Buds-2/battery-replacement/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-2/case-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-2/buds-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-2/speaker-issue/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-2/water-damage/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-2/rl-buds/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />       
        {/* Galaxy Buds 3 */}
        <Route path="/Galaxy-Buds-3/select-issue" element={<Issue selectedModel={selectedModel} setSelectedBrand={setSelectedBrand} setSelectedModel={setSelectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/Galaxy-Buds-3/battery-replacement/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-3/case-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-3/buds-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-3/speaker-issue/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-3/water-damage/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-3/rl-buds/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />       
        {/* Galaxy Buds 2 Pro */}
        <Route path="/Galaxy-Buds-2-Pro/select-issue" element={<Issue selectedModel={selectedModel} setSelectedBrand={setSelectedBrand} setSelectedModel={setSelectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/Galaxy-Buds-2-Pro/battery-replacement/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-2-Pro/case-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-2-Pro/buds-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-2-Pro/speaker-issue/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-2-Pro/water-damage/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-2-Pro/rl-buds/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />       
        {/* Galaxy Buds 3 Pro */}
        <Route path="/Galaxy-Buds-3-Pro/select-issue" element={<Issue selectedModel={selectedModel} setSelectedBrand={setSelectedBrand} setSelectedModel={setSelectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/Galaxy-Buds-3-Pro/battery-replacement/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-3-Pro/case-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-3-Pro/buds-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-3-Pro/speaker-issue/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-3-Pro/water-damage/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-3-Pro/rl-buds/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />       
        {/* Galaxy Buds Live */}
        <Route path="/Galaxy-Buds-Live/select-issue" element={<Issue selectedModel={selectedModel} setSelectedBrand={setSelectedBrand} setSelectedModel={setSelectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/Galaxy-Buds-Live/battery-replacement/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-Live/case-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-Live/buds-charging/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-Live/speaker-issue/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-Live/water-damage/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/Galaxy-Buds-Live/rl-buds/price-details" element={<PriceDetails selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />       

        {/* Laptop */}
        <Route path="/laptop/select-brand" element={<Laptop setSelectedBrand={setSelectedBrand} />} />


        {/* Apple */}
        <Route path="/apple-laptop/select-Model" element={<ModelLaptop selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/apple-others/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/apple-others/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/apple-others/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/apple-others/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/apple-others/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/apple-others/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/apple-others/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/apple-others/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/apple-others/other-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Macbook */}
        <Route path="/macbook-a1181/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-a1181/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1181/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1181/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1181/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1181/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1181/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1181/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Macbook */}
        <Route path="/macbook-a1278/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-a1278/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1278/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1278/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1278/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1278/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1278/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1278/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Macbook */}
        <Route path="/macbook-a1342/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-a1342/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1342/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1342/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1342/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1342/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1342/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1342/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Macbook */}
        <Route path="/macbook-a1534/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-a1534/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1534/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1534/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1534/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1534/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1534/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-a1534/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Macbook */}
        <Route path="/macbook-air-a2681/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-air-a2681/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a2681/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a2681/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a2681/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a2681/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a2681/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a2681/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Macbook */}
        <Route path="/macbook-air-a1369/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-air-a1369/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1369/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1369/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1369/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1369/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1369/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1369/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Macbook */}
        <Route path="/macbook-air-a1370/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-air-a1370/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1370/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1370/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1370/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1370/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1370/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1370/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Macbook */}
        <Route path="/macbook-air-a1465/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-air-a1465/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1465/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1465/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1465/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1465/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1465/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1465/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Macbook */}
        <Route path="/macbook-air-a1466/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-air-a1466/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1466/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1466/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1466/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1466/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1466/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1466/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Macbook */}
        <Route path="/macbook-air-a1932/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-air-a1932/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1932/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1932/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1932/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1932/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1932/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a1932/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Macbook */}
        <Route path="/macbook-air-a2179/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-air-a2179/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a2179/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a2179/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a2179/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a2179/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a2179/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a2179/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Macbook */}
        <Route path="/macbook-air-a2337/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-air-a2337/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a2337/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a2337/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a2337/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a2337/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a2337/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-air-a2337/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Macbook */}
        <Route path="/macbook-pro-a1706/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-pro-a1706/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1706/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1706/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1706/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1706/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1706/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1706/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Macbook */}
        <Route path="/macbook-pro-a1707/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-pro-a1707/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1707/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1707/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1707/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1707/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1707/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1707/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Macbook */}
        <Route path="/macbook-pro-a1708/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-pro-a1708/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1708/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1708/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1708/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1708/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1708/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1708/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Macbook */}
        <Route path="/macbook-pro-a1989/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-pro-a1989/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1989/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1989/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1989/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1989/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1989/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1989/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        
        {/* Macbook */}
        <Route path="/macbook-pro-a1990/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-pro-a1990/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1990/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1990/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1990/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1990/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1990/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a1990/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Macbook */}
        <Route path="/macbook-pro-a2141/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-pro-a2141/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2141/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2141/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2141/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2141/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2141/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2141/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Macbook */}
        <Route path="/macbook-pro-a2159/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-pro-a2159/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2159/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2159/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2159/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2159/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2159/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2159/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Macbook */}
        <Route path="/macbook-pro-a2251/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-pro-a2251/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2251/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2251/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2251/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2251/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2251/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2251/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Macbook */}
        <Route path="/macbook-pro-a2289/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-pro-a2289/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2289/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2289/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2289/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2289/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2289/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2289/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Macbook */}
        <Route path="/macbook-pro-a2338/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/macbook-pro-a2338/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2338/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2338/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2338/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2338/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2338/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/macbook-pro-a2338/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />


        {/* Samsung */}
        <Route path="/samsung-laptop/select-Model" element={<ModelLaptop selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/samsung-laptop-others/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/samsung-laptop-others/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/samsung-laptop-others/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/samsung-laptop-others/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/samsung-laptop-others/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/samsung-laptop-others/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/samsung-laptop-others/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/samsung-laptop-others/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/samsung-laptop-others/other-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Samsung Book */}
        <Route path="/galaxy-book-4ultra/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-book-4ultra/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-4ultra/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-4ultra/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-4ultra/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-4ultra/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-4ultra/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-4ultra/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Samsung Book */}
        <Route path="/galaxy-book-4pro/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-book-4pro/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-4pro/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-4pro/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-4pro/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-4pro/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-4pro/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-4pro/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Samsung Book */}
        <Route path="/galaxy-book-3ultra/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-book-3ultra/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-3ultra/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-3ultra/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-3ultra/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-3ultra/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-3ultra/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-3ultra/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Samsung Book */}
        <Route path="/galaxy-book-3pro360/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-book-3pro360/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-3pro360/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-3pro360/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-3pro360/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-3pro360/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-3pro360/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-3pro360/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Samsung Book */}
        <Route path="/galaxy-book-2pro360/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-book-2pro360/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-2pro360/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-2pro360/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-2pro360/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-2pro360/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-2pro360/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-2pro360/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Samsung Book */}
        <Route path="/galaxy-book-3pro/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-book-3pro/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-3pro/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-3pro/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-3pro/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-3pro/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-3pro/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-3pro/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Samsung Book */}
        <Route path="/galaxy-book-2/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-book2/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book2/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book2/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book2/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book2/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book2/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book2/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Samsung Book */}
        <Route path="/galaxy-book-2pro/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-book-2pro/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-2pro/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-2pro/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-2pro/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-2pro/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-2pro/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-2pro/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Samsung Book */}
        <Route path="/galaxy-book-pro360/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-book-pro360/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-pro360/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-pro360/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-pro360/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-pro360/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-pro360/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-pro360/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Samsung Book */}
        <Route path="/galaxy-book-pro/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-book-pro/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-pro/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-pro/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-pro/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-pro/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-pro/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-pro/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Samsung Book */}
        <Route path="/galaxy-book-go/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-book-go/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-go/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-go/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-go/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-go/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-go/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-go/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Samsung Book */}
        <Route path="/galaxy-book-4/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-book4/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book4/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book4/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book4/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book4/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book4/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book4/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Samsung Book */}
        <Route path="/galaxy-book-flex2/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-book-flex2/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-flex2/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-flex2/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-flex2/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-flex2/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-flex2/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-flex2/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Samsung Book */}
        <Route path="/galaxy-book-ion2/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-book-ion2/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-ion2/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-ion2/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-ion2/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-ion2/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-ion2/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-book-ion2/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Samsung Book */}
        <Route path="/galaxy-books/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-books/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-books/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-books/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-books/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-books/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-books/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-books/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        

        {/* Acer */}
        <Route path="/acer-laptop/select-Model" element={<ModelLaptop selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/acer-others/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/acer-others/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/acer-others/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/acer-others/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/acer-others/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/acer-others/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/acer-others/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/acer-others/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/acer-others/other-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Acer Laptop */}
        <Route path="/swift-x14/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/swift-x14/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-x14/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-x14/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-x14/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-x14/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-x14/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-x14/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Acer Laptop */}
        <Route path="/swift-3oled/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/swift-3oled/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-3oled/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-3oled/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-3oled/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-3oled/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-3oled/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-3oled/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Acer Laptop */}
        <Route path="/swift-x/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/swift-x/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-x/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-x/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-x/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-x/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-x/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-x/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Acer Laptop */}
        <Route path="/swift-5/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/swift-5/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-5/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-5/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-5/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-5/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-5/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/swift-5/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Acer Laptop */}
        <Route path="/chromebook-515/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/chromebook-515/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-515/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-515/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-515/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-515/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-515/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-515/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Acer Laptop */}
        <Route path="/chromebook-514/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/chromebook-514/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-514/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-514/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-514/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-514/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-514/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-514/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Acer Laptop */}
        <Route path="/chromebook-spin714/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/chromebook-spin714/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-spin714/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-spin714/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-spin714/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-spin714/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-spin714/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-spin714/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Acer Laptop */}
        <Route path="/chromebook-spin713/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/chromebook-spin713/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-spin713/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-spin713/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-spin713/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-spin713/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-spin713/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/chromebook-spin713/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Acer Laptop */}
        <Route path="/predator-helios-neo16/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/predator-helios-neo16/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-helios-neo16/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-helios-neo16/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-helios-neo16/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-helios-neo16/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-helios-neo16/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-helios-neo16/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Acer Laptop */}
        <Route path="/predator-triton-500se/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/predator-triton-500se/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-triton-500se/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-triton-500se/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-triton-500se/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-triton-500se/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-triton-500se/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-triton-500se/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Acer Laptop */}
        <Route path="/predator-helios-300/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/predator-helios300/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-helios300/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-helios300/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-helios300/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-helios300/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-helios300/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-helios300/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Acer Laptop */}
        <Route path="/predator-helios-700/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/predator-helios700/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-helios700/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-helios700/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-helios700/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-helios700/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-helios700/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/predator-helios700/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Acer Laptop */}
        <Route path="/nitro-5/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/nitro-5/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/nitro-5/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/nitro-5/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/nitro-5/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/nitro-5/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/nitro-5/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/nitro-5/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Acer Laptop */}
        <Route path="/nitro-7/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/nitro-7/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/nitro-7/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/nitro-7/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/nitro-7/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/nitro-7/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/nitro-7/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/nitro-7/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Acer Laptop */}
        <Route path="/aspire-3/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/aspire-3/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-3/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-3/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-3/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-3/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-3/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-3/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Acer Laptop */}
        <Route path="/aspire-vero/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/aspire-vero/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-vero/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-vero/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-vero/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-vero/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-vero/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-vero/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Acer Laptop */}
        <Route path="/aspire-5/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/aspire-5/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-5/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-5/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-5/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-5/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-5/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-5/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Acer Laptop */}
        <Route path="/aspire-7/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/aspire-7/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-7/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-7/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-7/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-7/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-7/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/aspire-7/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />


        {/* Asus */}
        <Route path="/asus-laptop/select-Model" element={<ModelLaptop selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/asus-others/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/asus-others/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/asus-others/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/asus-others/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/asus-others/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/asus-others/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/asus-others/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/asus-others/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/asus-others/other-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
      
        {/* Asus Laptop */}
        <Route path="/zenbook-14oled/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/zenbook-14oled/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-14oled/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-14oled/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-14oled/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-14oled/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-14oled/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-14oled/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Asus Laptop */}
        <Route path="/zenbook-17foldoled/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/zenbook-17fold-oled/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-17fold-oled/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-17fold-oled/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-17fold-oled/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-17fold-oled/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-17fold-oled/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-17fold-oled/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Asus Laptop */}
        <Route path="/zenbook-duo14/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/zenbook-duo14/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-duo14/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-duo14/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-duo14/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-duo14/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-duo14/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-duo14/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Asus Laptop */}
        <Route path="/zenbook-pro-duo14/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/zenbook-pro-duo14/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-pro-duo14/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-pro-duo14/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-pro-duo14/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-pro-duo14/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-pro-duo14/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/zenbook-pro-duo14/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Asus Laptop */}
        <Route path="/rog-zephyrus-g14/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/rog-zephyrus-g14/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-zephyrus-g14/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-zephyrus-g14/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-zephyrus-g14/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-zephyrus-g14/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-zephyrus-g14/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-zephyrus-g14/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Asus Laptop */}
        <Route path="/rog-zephyrus-g16/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/rog-zephyrus-g16/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-zephyrus-g16/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-zephyrus-g16/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-zephyrus-g16/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-zephyrus-g16/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-zephyrus-g16/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-zephyrus-g16/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Asus Laptop */}
        <Route path="/rog-strix-g15/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/rog-strix-g15/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-strix-g15/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-strix-g15/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-strix-g15/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-strix-g15/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-strix-g15/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-strix-g15/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Asus Laptop */}
        <Route path="/rog-strix-scar18/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/rog-strix-scar18/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-strix-scar18/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-strix-scar18/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-strix-scar18/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-strix-scar18/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-strix-scar18/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/rog-strix-scar18/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Asus Laptop */}
        <Route path="/vivobook-s15/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivobook-s15/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-s15/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-s15/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-s15/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-s15/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-s15/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-s15/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Asus Laptop */}
        <Route path="/vivobook-15xoled/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivobook-15xoled/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-15xoled/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-15xoled/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-15xoled/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-15xoled/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-15xoled/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-15xoled/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Asus Laptop */}
        <Route path="/vivobook-pro16x/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivobook-pro16x/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-pro16x/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-pro16x/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-pro16x/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-pro16x/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-pro16x/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-pro16x/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Asus Laptop */}
        <Route path="/vivobook-flip14/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivobook-flip14/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-flip14/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-flip14/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-flip14/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-flip14/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-flip14/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivobook-flip14/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Asus Laptop */}
        <Route path="/expertbook-b5/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/expertbook-b5/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-b5/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-b5/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-b5/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-b5/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-b5/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-b5/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Asus Laptop */}
        <Route path="/expertbook-b9/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/expertbook-b9/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-b9/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-b9/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-b9/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-b9/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-b9/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-b9/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Asus Laptop */}
        <Route path="/expertbook-b7flip/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/expertbook-b7flip/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-b7flip/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-b7flip/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-b7flip/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-b7flip/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-b7flip/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-b7flip/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Asus Laptop */}
        <Route path="/expertbook-p2/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/expertbook-p2/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-p2/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-p2/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-p2/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-p2/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-p2/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/expertbook-p2/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Asus Laptop */}
        <Route path="/tuf-gaming-a15/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/tuf-gaminga15/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-gaminga15/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-gaminga15/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-gaminga15/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-gaminga15/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-gaminga15/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-gaminga15/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Asus Laptop */}
        <Route path="/tuf-gaming-a17/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/tuf-gaminga17/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-gaminga17/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-gaminga17/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-gaminga17/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-gaminga17/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-gaminga17/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-gaminga17/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Asus Laptop */}
        <Route path="/tuf-gaming-f17/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/tuf-gamingf17/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-gamingf17/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-gamingf17/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-gamingf17/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-gamingf17/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-gamingf17/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-gamingf17/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Asus Laptop */}
        <Route path="/tuf-dash-f15/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/tuf-dashf15/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-dashf15/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-dashf15/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-dashf15/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-dashf15/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-dashf15/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/tuf-dashf15/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Dell */}
        <Route path="/dell-laptop/select-Model" element={<ModelLaptop selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/dell-others/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/dell-others/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/dell-others/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/dell-others/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/dell-others/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/dell-others/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/dell-others/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/dell-others/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/dell-others/other-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Dell Laptop */}
        <Route path="/inspiron-16plus/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/inspiron-16plus/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-16plus/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-16plus/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-16plus/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-16plus/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-16plus/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-16plus/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Dell Laptop */}
        <Route path="/inspiron-15/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/inspiron-15/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-15/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-15/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-15/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-15/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-15/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-15/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Dell Laptop */}
        <Route path="/inspiron-14/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/inspiron-14/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-14/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-14/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-14/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-14/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-14/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-14/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Dell Laptop */}
        <Route path="/inspiron-13/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/inspiron-13/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-13/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-13/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-13/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-13/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-13/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/inspiron-13/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Dell Laptop */}
        <Route path="/precision-5690/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/precision-5690/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5690/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5690/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5690/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5690/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5690/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5690/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Dell Laptop */}
        <Route path="/precision-5570/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/precision-5570/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5570/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5570/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5570/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5570/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5570/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5570/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Dell Laptop */}
        <Route path="/precision-5550/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/precision-5550/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5550/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5550/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5550/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5550/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5550/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5550/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Dell Laptop */}
        <Route path="/precision-5540/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/precision-5540/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5540/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5540/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5540/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5540/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5540/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/precision-5540/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Dell Laptop */}
        <Route path="/alienware-m15r2/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/alienware-m15r2/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-m15r2/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-m15r2/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-m15r2/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-m15r2/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-m15r2/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-m15r2/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Dell Laptop */}
        <Route path="/alienware-m16r2/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/alienware-m16r2/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-m16r2/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-m16r2/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-m16r2/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-m16r2/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-m16r2/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-m16r2/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Dell Laptop */}
        <Route path="/alienware-x14r2/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/alienware-x14r2/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-x14r2/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-x14r2/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-x14r2/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-x14r2/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-x14r2/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-x14r2/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Dell Laptop */}
        <Route path="/alienware-x17r2/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/alienware-x17r2/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-x17r2/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-x17r2/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-x17r2/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-x17r2/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-x17r2/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/alienware-x17r2/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Dell Laptop */}
        <Route path="/latitude-9440/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/latitude-9440/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-9440/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-9440/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-9440/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-9440/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-9440/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-9440/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Dell Laptop */}
        <Route path="/latitude-7330/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/latitude-7330/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-7330/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-7330/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-7330/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-7330/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-7330/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-7330/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Dell Laptop */}
        <Route path="/latitude-5430/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/latitude-5430/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-5430/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-5430/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-5430/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-5430/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-5430/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-5430/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Dell Laptop */}
        <Route path="/latitude-7420/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/latitude-7420/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-7420/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-7420/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-7420/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-7420/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-7420/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/latitude-7420/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Dell Laptop */}
        <Route path="/xps-16/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/xps-16/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-16/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-16/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-16/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-16/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-16/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-16/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Dell Laptop */}
        <Route path="/xps-14/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/xps-14/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-14/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-14/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-14/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-14/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-14/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-14/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Dell Laptop */}
        <Route path="/xps-13plus/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/xps-13plus/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-13plus/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-13plus/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-13plus/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-13plus/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-13plus/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-13plus/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Dell Laptop */}
        <Route path="/xps-15/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/xps-15/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-15/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-15/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-15/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-15/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-15/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/xps-15/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* HP */}
        <Route path="/hp-laptop/select-Model" element={<ModelLaptop selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/hp-others/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/hp-others/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/hp-others/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/hp-others/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/hp-others/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/hp-others/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/hp-others/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/hp-others/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/hp-others/other-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* HP Laptop */}
        <Route path="/pavilion-15/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pavilion-15/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-15/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-15/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-15/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-15/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-15/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-15/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* HP Laptop */}
        <Route path="/pavilion-aero13/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pavilion-aero13/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-aero13/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-aero13/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-aero13/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-aero13/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-aero13/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-aero13/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* HP Laptop */}
        <Route path="/pavilion-x36014/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pavilion-x36014/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-x36014/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-x36014/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-x36014/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-x36014/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-x36014/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-x36014/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* HP Laptop */}
        <Route path="/pavilion-x36015/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pavilion-x36015/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-x36015/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-x36015/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-x36015/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-x36015/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-x36015/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pavilion-x36015/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* HP Laptop */}
        <Route path="/spectre-x36013.5/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/spectre-x36013.5/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/spectre-x36013.5/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/spectre-x36013.5/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/spectre-x36013.5/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/spectre-x36013.5/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/spectre-x36013.5/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/spectre-x36013.5/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* HP Laptop */}
        <Route path="/spectre-x36014/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/spectre-x36014/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/spectre-x36014/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/spectre-x36014/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/spectre-x36014/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/spectre-x36014/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/spectre-x36014/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/spectre-x36014/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* HP Laptop */}
        <Route path="/spectre-x36016/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/spectre-x36016/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/spectre-x36016/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/spectre-x36016/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/spectre-x36016/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/spectre-x36016/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/spectre-x36016/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/spectre-x36016/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* HP Laptop */}
        <Route path="/omen-15/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/omen-15/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/omen-15/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/omen-15/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/omen-15/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/omen-15/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/omen-15/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/omen-15/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* HP Laptop */}
        <Route path="/omen-16/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/omen-16/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/omen-16/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/omen-16/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/omen-16/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/omen-16/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/omen-16/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/omen-16/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* HP Laptop */}
        <Route path="/omen-17/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/omen-17/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/omen-17/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/omen-17/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/omen-17/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/omen-17/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/omen-17/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/omen-17/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* HP Laptop */}
        <Route path="/envy-14/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/envy-14/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-14/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-14/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-14/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-14/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-14/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-14/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* HP Laptop */}
        <Route path="/envy-16/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/envy-16/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-16/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-16/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-16/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-16/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-16/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-16/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* HP Laptop */}
        <Route path="/envy-x36013/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/envy-x36013/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-x36013/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-x36013/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-x36013/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-x36013/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-x36013/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-x36013/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* HP Laptop */}
        <Route path="/envy-x36015/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/envy-x36015/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-x36015/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-x36015/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-x36015/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-x36015/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-x36015/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/envy-x36015/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Lenovo */}
        <Route path="/lenovo-laptop/select-Model" element={<ModelLaptop selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/lenovo-laptop-others/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/lenovo-laptop-others/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/lenovo-laptop-others/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/lenovo-laptop-others/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/lenovo-laptop-others/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/lenovo-laptop-others/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/lenovo-laptop-others/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/lenovo-laptop-others/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/lenovo-laptop-others/other-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
    
        {/* Lenovo Laptop */}
        <Route path="/ideapad-3/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ideapad-3/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-3/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-3/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-3/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-3/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-3/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-3/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Lenovo Laptop */}
        <Route path="/ideapad-5pro/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ideapad-5pro/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-5pro/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-5pro/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-5pro/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-5pro/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-5pro/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-5pro/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Lenovo Laptop */}
        <Route path="/ideapad-flex-5i14/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ideapad-flex-5i14/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-flex-5i14/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-flex-5i14/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-flex-5i14/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-flex-5i14/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-flex-5i14/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-flex-5i14/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Lenovo Laptop */}
        <Route path="/ideapad-s540/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ideapad-s540/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-s540/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-s540/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-s540/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-s540/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-s540/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ideapad-s540/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Lenovo Laptop */}
        <Route path="/thinkpad-p1/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/thinkpad-p1/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-p1/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-p1/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-p1/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-p1/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-p1/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-p1/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Lenovo Laptop */}
        <Route path="/thinkpad-t14s/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/thinkpad-t14s/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-t14s/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-t14s/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-t14s/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-t14s/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-t14s/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-t14s/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Lenovo Laptop */}
        <Route path="/thinkpad-x1nano/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/thinkpad-x1nano/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-x1nano/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-x1nano/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-x1nano/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-x1nano/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-x1nano/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-x1nano/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Lenovo Laptop */}
        <Route path="/thinkpad-x1carbon/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/thinkpad-x1carbon/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-x1carbon/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-x1carbon/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-x1carbon/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-x1carbon/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-x1carbon/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/thinkpad-x1carbon/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Lenovo Laptop */}
        <Route path="/yoga-7igen8/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/yoga-7igen8/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-7igen8/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-7igen8/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-7igen8/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-7igen8/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-7igen8/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-7igen8/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Lenovo Laptop */}
        <Route path="/yoga-9igen6/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/yoga-9igen6/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-9igen6/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-9igen6/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-9igen6/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-9igen6/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-9igen6/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-9igen6/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Lenovo Laptop */}
        <Route path="/yoga-9igen7/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/yoga-9igen7/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-9igen7/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-9igen7/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-9igen7/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-9igen7/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-9igen7/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-9igen7/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Lenovo Laptop */}
        <Route path="/yoga-9igen9/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/yoga-9igen9/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-9igen9/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-9igen9/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-9igen9/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-9igen9/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-9igen9/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/yoga-9igen9/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Lenovo Laptop */}
        <Route path="/legion-slim5/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/legion-slim5/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-slim5/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-slim5/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-slim5/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-slim5/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-slim5/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-slim5/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Lenovo Laptop */}
        <Route path="/legion-5pro/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/legion-5pro/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-5pro/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-5pro/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-5pro/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-5pro/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-5pro/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-5pro/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Lenovo Laptop */}
        <Route path="/legion-7igen6/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/legion-7igen6/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-7igen6/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-7igen6/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-7igen6/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-7igen6/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-7igen6/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-7igen6/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Lenovo Laptop */}
        <Route path="/legion-5ipro/select-issue" element={<IssueLaptop selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/legion-5ipro/battery-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-5ipro/keyboard-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-5ipro/motherboard-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-5ipro/trackpad-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-5ipro/screen-replacement/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-5ipro/ssd-upgrade/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/legion-5ipro/webcam-issue/price-details" element={<PriceDetailsLaptop selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />


        {/* Mobile */}
        <Route path="/mobile/select-brand" element={<Mobile setSelectedBrand={setSelectedBrand} />} />

        {/* Lenovo */}
        <Route path="/lenovo-mobile/select-Model" element={<ModelMobile selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/lenovo-others/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/lenovo-others/other-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/lenovo-others/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/lenovo-others/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/lenovo-others/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/lenovo-others/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/lenovo-others/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/lenovo-others/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/lenovo-others/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/lenovo-others/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/lenovo-others/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/lenovo-others/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/lenovo-others/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/lenovo-others/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/lenovo-others/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/lenovo-others/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/lenovo-others/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/lenovo-others/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/lenovo-others/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/lenovo-others/other-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Legion Y90 */}
        <Route path="/legion-y90/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/legion-y90/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y90/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y90/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y90/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y90/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y90/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y90/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y90/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y90/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y90/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y90/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y90/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y90/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y90/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y90/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y90/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y90/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        
        {/* Legion Y70 */}
        <Route path="/legion-y70/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/legion-y70/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y70/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y70/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y70/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y70/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y70/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y70/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y70/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y70/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y70/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y70/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y70/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y70/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y70/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y70/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y70/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-y70/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Legion Phone Pro */}
        <Route path="/legion-phone-pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/legion-phone-pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-phone-pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-phone-pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-phone-pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-phone-pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-phone-pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-phone-pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-phone-pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-phone-pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-phone-pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-phone-pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-phone-pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-phone-pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-phone-pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-phone-pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-phone-pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/legion-phone-pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Apple */}
        <Route path="/apple-iphone/select-Model" element={<ModelMobile selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/apple-others/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/apple-others/other-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/apple-others/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/apple-others/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/apple-others/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/apple-others/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/apple-others/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/apple-others/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/apple-others/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/apple-others/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/apple-others/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/apple-others/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/apple-others/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/apple-others/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/apple-others/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/apple-others/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/apple-others/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/apple-others/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/apple-others/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/apple-others/other-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Iphone */}
        <Route path="/iphone-16/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-16/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-16plus/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-16plus/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16plus/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16plus/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16plus/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16plus/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16plus/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16plus/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16plus/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16plus/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16plus/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16plus/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16plus/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16plus/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16plus/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16plus/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16plus/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16plus/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-16pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-16pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-16promax/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-16promax/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16promax/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16promax/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16promax/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16promax/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16promax/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16promax/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16promax/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16promax/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16promax/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16promax/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16promax/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16promax/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16promax/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16promax/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16promax/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-16promax/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-7/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-7/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-7plus/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-7plus/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7plus/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7plus/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7plus/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7plus/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7plus/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7plus/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7plus/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7plus/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7plus/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7plus/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7plus/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7plus/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7plus/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7plus/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7plus/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-7plus/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-8/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-8/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-8plus/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-8plus/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8plus/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8plus/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8plus/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8plus/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8plus/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8plus/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8plus/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8plus/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8plus/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8plus/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8plus/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8plus/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8plus/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8plus/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8plus/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-8plus/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-x/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-x/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-x/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-x/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-x/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-x/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-x/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-x/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-x/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-x/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-x/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-x/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-x/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-x/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-x/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-x/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-x/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-x/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-xr/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-xr/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xr/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xr/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xr/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xr/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xr/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xr/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xr/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xr/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xr/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xr/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xr/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xr/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xr/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xr/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xr/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xr/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-xs/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-xs/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xs/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xs/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xs/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xs/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xs/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xs/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xs/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xs/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xs/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xs/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xs/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xs/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xs/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xs/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xs/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xs/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-xsmax/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-xsmax/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xsmax/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xsmax/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xsmax/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xsmax/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xsmax/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xsmax/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xsmax/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xsmax/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xsmax/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xsmax/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xsmax/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xsmax/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xsmax/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xsmax/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xsmax/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-xsmax/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-se1gen/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-se1gen/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se1gen/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se1gen/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se1gen/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se1gen/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se1gen/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se1gen/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se1gen/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se1gen/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se1gen/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se1gen/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se1gen/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se1gen/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se1gen/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se1gen/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se1gen/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se1gen/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-se2gen/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-se2gen/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se2gen/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se2gen/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se2gen/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se2gen/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se2gen/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se2gen/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se2gen/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se2gen/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se2gen/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se2gen/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se2gen/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se2gen/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se2gen/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se2gen/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se2gen/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se2gen/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-se3gen/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-se3gen/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se3gen/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se3gen/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se3gen/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se3gen/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se3gen/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se3gen/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se3gen/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se3gen/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se3gen/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se3gen/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se3gen/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se3gen/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se3gen/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se3gen/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se3gen/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-se3gen/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-11/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-11/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-11pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-11pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-11promax/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-11promax/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11promax/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11promax/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11promax/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11promax/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11promax/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11promax/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11promax/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11promax/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11promax/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11promax/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11promax/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11promax/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11promax/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11promax/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11promax/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-11promax/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-12/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-12/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-12mini/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-12mini/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12mini/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12mini/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12mini/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12mini/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12mini/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12mini/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12mini/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12mini/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12mini/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12mini/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12mini/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12mini/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12mini/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12mini/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12mini/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12mini/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-12pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-12pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-12promax/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-12promax/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12promax/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12promax/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12promax/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12promax/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12promax/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12promax/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12promax/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12promax/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12promax/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12promax/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12promax/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12promax/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12promax/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12promax/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12promax/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-12promax/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-13/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-13/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-13mini/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-13mini/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13mini/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13mini/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13mini/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13mini/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13mini/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13mini/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13mini/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13mini/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13mini/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13mini/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13mini/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13mini/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13mini/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13mini/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13mini/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13mini/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-13pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-13pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-13promax/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-13promax/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13promax/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13promax/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13promax/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13promax/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13promax/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13promax/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13promax/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13promax/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13promax/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13promax/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13promax/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13promax/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13promax/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13promax/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13promax/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-13promax/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-14/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-14/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-14plus/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-14plus/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14plus/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14plus/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14plus/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14plus/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14plus/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14plus/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14plus/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14plus/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14plus/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14plus/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14plus/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14plus/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14plus/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14plus/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14plus/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14plus/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-14pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-14pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-14promax/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-14promax/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14promax/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14promax/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14promax/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14promax/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14promax/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14promax/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14promax/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14promax/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14promax/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14promax/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14promax/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14promax/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14promax/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14promax/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14promax/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-14promax/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-15/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-15/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-15plus/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-15plus/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15plus/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15plus/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15plus/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15plus/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15plus/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15plus/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15plus/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15plus/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15plus/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15plus/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15plus/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15plus/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15plus/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15plus/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15plus/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15plus/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-15pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-15pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Iphone */}
        <Route path="/iphone-15promax/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iphone-15promax/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15promax/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15promax/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15promax/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15promax/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15promax/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15promax/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15promax/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15promax/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15promax/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15promax/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15promax/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15promax/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15promax/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15promax/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15promax/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/iphone-15promax/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />


        {/* OnePlus */}
        <Route path="/oneplus-mobile/select-Model" element={<ModelMobile selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/oneplus-others/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-others/other-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-others/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-others/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-others/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-others/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-others/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-others/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-others/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-others/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-others/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-others/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-others/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-others/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-others/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-others/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-others/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-others/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-others/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/oneplus-others/other-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* OnePlus Mobile */}
        <Route path="/oneplus-9r/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-9r/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9r/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9r/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9r/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9r/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9r/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9r/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9r/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9r/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9r/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9r/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9r/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9r/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9r/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9r/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9r/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9r/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Mobile */}
        <Route path="/oneplus-10pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-10pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Mobile */}
        <Route path="/oneplus-10r/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-10r/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10r/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10r/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10r/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10r/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10r/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10r/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10r/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10r/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10r/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10r/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10r/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10r/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10r/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10r/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10r/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-10r/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Mobile */}
        <Route path="/oneplus-11/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-11/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-11/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-11/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-11/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-11/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-11/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-11/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-11/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-11/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-11/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-11/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-11/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-11/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-11/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-11/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-11/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-11/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Mobile */}
        <Route path="/oneplus-12/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-12/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-12/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-12/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-12/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-12/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-12/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-12/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-12/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-12/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-12/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-12/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-12/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-12/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-12/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-12/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-12/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-12/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Mobile */}
        <Route path="/oneplus-8/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-8/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Mobile */}
        <Route path="/oneplus-8pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-8pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Mobile */}
        <Route path="/oneplus-8t/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-8t/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8t/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8t/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8t/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8t/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8t/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8t/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8t/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8t/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8t/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8t/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8t/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8t/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8t/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8t/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8t/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-8t/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Mobile */}
        <Route path="/oneplus-9/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-9/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Mobile */}
        <Route path="/oneplus-9pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-9pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-9pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Mobile */}
        <Route path="/oneplus-ace2pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-ace2pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-ace2pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-ace2pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-ace2pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-ace2pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-ace2pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-ace2pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-ace2pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-ace2pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-ace2pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-ace2pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-ace2pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-ace2pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-ace2pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-ace2pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-ace2pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-ace2pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Mobile */}
        <Route path="/oneplus-nordn200/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-nordn200/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn200/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn200/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn200/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn200/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn200/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn200/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn200/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn200/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn200/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn200/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn200/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn200/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn200/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn200/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn200/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn200/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Mobile */}
        <Route path="/oneplus-nordn30/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-nordn30/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn30/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn30/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn30/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn30/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn30/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn30/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn30/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn30/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn30/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn30/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn30/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn30/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn30/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn30/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn30/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn30/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Mobile */}
        <Route path="/oneplus-nord/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-nord/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Mobile */}
        <Route path="/oneplus-nord2/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-nord2/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Mobile */}
        <Route path="/oneplus-nord2t/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-nord2t/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2t/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2t/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2t/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2t/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2t/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2t/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2t/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2t/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2t/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2t/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2t/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2t/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2t/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2t/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2t/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nord2t/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Mobile */}
        <Route path="/oneplus-nordn100/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-nordn100/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn100/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn100/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn100/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn100/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn100/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn100/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn100/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn100/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn100/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn100/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn100/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn100/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn100/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn100/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn100/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordn100/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Mobile */}
        <Route path="/oneplus-nordce/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-nordce/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Mobile */}
        <Route path="/oneplus-nordce2/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-nordce2/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce2/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce2/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce2/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce2/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce2/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce2/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce2/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce2/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce2/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce2/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce2/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce2/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce2/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce2/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce2/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce2/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Mobile */}
        <Route path="/oneplus-nordce3/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-nordce3/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce3/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce3/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce3/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce3/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce3/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce3/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce3/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce3/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce3/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce3/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce3/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce3/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce3/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce3/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce3/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oneplus-nordce3/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />


        {/* Techno */}
        <Route path="/techno-mobile/select-Model" element={<ModelMobile selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/techno-others/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/techno-others/other-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/techno-others/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/techno-others/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/techno-others/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/techno-others/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/techno-others/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/techno-others/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/techno-others/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/techno-others/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/techno-others/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/techno-others/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/techno-others/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/techno-others/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/techno-others/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/techno-others/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/techno-others/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/techno-others/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/techno-others/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/techno-others/other-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Techno Mobile */}
        <Route path="/camon-15pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/camon-15pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-15pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-15pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-15pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-15pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-15pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-15pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-15pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-15pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-15pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-15pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-15pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-15pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-15pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-15pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-15pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-15pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Techno Mobile */}
        <Route path="/camon-16pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/camon-16pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-16pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-16pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-16pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-16pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-16pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-16pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-16pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-16pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-16pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-16pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-16pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-16pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-16pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-16pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-16pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-16pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Techno Mobile */}
        <Route path="/camon-18/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/camon-18/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-18/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-18/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-18/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-18/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-18/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-18/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-18/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-18/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-18/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-18/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-18/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-18/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-18/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-18/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-18/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-18/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Techno Mobile */}
        <Route path="/camon-17/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/camon-17/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-17/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-17/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-17/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-17/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-17/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-17/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-17/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-17/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-17/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-17/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-17/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-17/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-17/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-17/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-17/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-17/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Techno Mobile */}
        <Route path="/camon-19/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/camon-19/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-19/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-19/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-19/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-19/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-19/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-19/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-19/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-19/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-19/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-19/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-19/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-19/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-19/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-19/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-19/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-19/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Techno Mobile */}
        <Route path="/camon-20pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/camon-20pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-20pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-20pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-20pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-20pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-20pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-20pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-20pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-20pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-20pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-20pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-20pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-20pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-20pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-20pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-20pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/camon-20pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Techno Mobile */}
        <Route path="/spark-10/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/spark-10/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-10/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-10/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-10/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-10/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-10/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-10/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-10/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-10/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-10/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-10/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-10/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-10/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-10/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-10/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-10/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-10/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Techno Mobile */}
        <Route path="/spark-8pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/spark-8pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-8pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-8pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-8pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-8pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-8pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-8pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-8pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-8pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-8pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-8pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-8pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-8pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-8pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-8pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-8pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-8pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Techno Mobile */}
        <Route path="/spark-5pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/spark-5pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-5pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-5pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-5pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-5pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-5pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-5pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-5pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-5pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-5pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-5pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-5pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-5pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-5pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-5pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-5pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-5pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Techno Mobile */}
        <Route path="/spark-6pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/spark-6pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-6pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-6pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-6pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-6pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-6pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-6pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-6pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-6pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-6pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-6pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-6pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-6pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-6pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-6pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-6pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/spark-6pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Techno Mobile */}
        <Route path="/phantom-x2pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/phantom-x2pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/phantom-x2pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/phantom-x2pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/phantom-x2pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/phantom-x2pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/phantom-x2pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/phantom-x2pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/phantom-x2pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/phantom-x2pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/phantom-x2pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/phantom-x2pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/phantom-x2pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/phantom-x2pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/phantom-x2pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/phantom-x2pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/phantom-x2pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/phantom-x2pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />


        {/* Samsung */}
        <Route path="/galaxy-mobile/select-Model" element={<ModelMobile selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/samsung-others/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/samsung-others/other-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/samsung-others/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/samsung-others/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/samsung-others/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/samsung-others/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/samsung-others/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/samsung-others/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/samsung-others/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/samsung-others/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/samsung-others/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/samsung-others/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/samsung-others/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/samsung-others/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/samsung-others/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/samsung-others/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/samsung-others/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/samsung-others/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/samsung-others/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/samsung-others/other-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Samsung Mobile */}
        <Route path="/galaxy-s24ultra/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-s24ultra/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24ultra/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24ultra/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24ultra/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24ultra/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24ultra/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24ultra/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24ultra/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24ultra/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24ultra/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24ultra/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24ultra/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24ultra/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24ultra/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24ultra/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24ultra/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24ultra/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-s23ultra/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-s23ultra/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23ultra/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23ultra/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23ultra/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23ultra/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23ultra/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23ultra/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23ultra/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23ultra/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23ultra/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23ultra/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23ultra/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23ultra/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23ultra/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23ultra/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23ultra/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23ultra/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-s22ultra/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-s22ultra/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22ultra/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22ultra/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22ultra/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22ultra/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22ultra/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22ultra/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22ultra/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22ultra/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22ultra/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22ultra/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22ultra/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22ultra/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22ultra/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22ultra/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22ultra/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22ultra/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-s21ultra/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-s21ultra/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21ultra/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21ultra/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21ultra/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21ultra/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21ultra/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21ultra/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21ultra/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21ultra/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21ultra/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21ultra/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21ultra/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21ultra/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21ultra/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21ultra/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21ultra/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21ultra/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-s24+/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-s24+/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24+/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24+/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24+/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24+/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24+/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24+/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24+/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24+/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24+/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24+/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24+/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24+/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24+/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24+/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24+/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24+/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-s24/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-s24/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s24/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-s23+/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-s23+/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23+/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23+/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23+/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23+/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23+/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23+/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23+/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23+/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23+/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23+/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23+/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23+/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23+/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23+/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23+/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23+/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-s23/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-s23/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s23/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-s22+/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-s22+/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22+/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22+/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22+/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22+/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22+/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22+/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22+/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22+/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22+/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22+/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22+/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22+/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22+/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22+/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22+/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22+/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-s22/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-s22/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s22/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-s21+/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-s21+/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21+/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21+/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21+/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21+/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21+/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21+/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21+/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21+/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21+/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21+/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21+/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21+/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21+/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21+/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21+/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21+/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-s21/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-s21/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s21/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-s20ultra/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-s20ultra/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20ultra/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20ultra/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20ultra/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20ultra/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20ultra/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20ultra/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20ultra/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20ultra/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20ultra/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20ultra/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20ultra/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20ultra/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20ultra/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20ultra/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20ultra/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20ultra/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-s20+/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-s20+/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20+/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20+/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20+/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20+/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20+/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20+/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20+/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20+/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20+/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20+/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20+/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20+/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20+/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20+/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20+/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20+/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-s20/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-s20/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s20/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-s10+/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-s10+/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10+/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10+/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10+/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10+/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10+/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10+/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10+/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10+/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10+/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10+/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10+/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10+/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10+/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10+/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10+/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10+/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-s10/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-s10/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-s10e/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-s10e/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10e/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10e/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10e/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10e/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10e/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10e/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10e/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10e/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10e/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10e/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10e/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10e/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10e/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10e/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10e/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s10e/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-s9+/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-s9+/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9+/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9+/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9+/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9+/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9+/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9+/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9+/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9+/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9+/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9+/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9+/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9+/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9+/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9+/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9+/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9+/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-s9/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-s9/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-s9/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-a54/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-a54/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a54/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a54/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a54/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a54/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a54/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a54/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a54/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a54/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a54/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a54/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a54/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a54/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a54/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a54/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a54/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a54/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-a34/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-a34/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a34/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a34/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a34/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a34/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a34/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a34/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a34/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a34/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a34/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a34/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a34/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a34/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a34/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a34/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a34/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a34/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-a24/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-a24/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a24/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a24/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a24/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a24/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a24/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a24/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a24/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a24/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a24/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a24/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a24/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a24/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a24/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a24/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a24/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a24/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-a23/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-a23/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-a14/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-a14/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a14/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a14/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a14/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a14/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a14/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a14/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a14/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a14/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a14/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a14/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a14/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a14/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a14/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a14/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a14/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a14/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-a73/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-a73/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a73/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a73/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a73/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a73/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a73/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a73/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a73/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a73/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a73/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a73/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a73/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a73/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a73/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a73/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a73/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a73/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-a53/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-a53/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a53/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a53/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a53/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a53/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a53/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a53/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a53/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a53/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a53/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a53/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a53/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a53/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a53/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a53/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a53/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a53/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-a33/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-a33/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a33/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a33/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a33/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a33/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a33/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a33/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a33/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a33/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a33/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a33/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a33/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a33/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a33/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a33/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a33/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a33/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-a23/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-a23/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a23/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-a13/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-a13/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a13/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a13/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a13/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a13/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a13/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a13/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a13/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a13/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a13/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a13/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a13/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a13/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a13/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a13/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a13/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a13/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-a72/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-a72/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a72/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a72/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a72/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a72/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a72/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a72/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a72/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a72/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a72/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a72/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a72/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a72/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a72/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a72/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a72/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a72/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-a52/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-a52/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a52/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a52/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a52/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a52/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a52/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a52/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a52/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a52/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a52/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a52/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a52/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a52/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a52/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a52/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a52/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a52/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-a42/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-a42/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a42/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a42/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a42/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a42/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a42/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a42/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a42/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a42/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a42/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a42/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a42/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a42/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a42/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a42/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a42/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a42/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-a32/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-a32/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a32/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a32/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a32/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a32/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a32/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a32/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a32/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a32/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a32/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a32/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a32/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a32/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a32/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a32/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a32/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a32/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-a12/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-a12/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a12/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a12/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a12/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a12/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a12/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a12/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a12/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a12/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a12/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a12/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a12/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a12/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a12/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a12/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a12/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a12/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-a71/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-a71/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a71/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a71/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a71/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a71/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a71/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a71/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a71/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a71/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a71/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a71/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a71/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a71/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a71/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a71/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a71/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a71/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-a51/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-a51/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a51/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a51/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a51/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a51/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a51/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a51/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a51/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a51/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a51/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a51/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a51/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a51/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a51/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a51/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a51/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a51/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-a41/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-a41/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a41/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a41/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a41/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a41/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a41/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a41/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a41/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a41/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a41/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a41/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a41/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a41/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a41/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a41/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a41/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a41/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Samsung Mobile */}
        <Route path="/galaxy-a31/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-a31/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a31/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a31/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a31/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a31/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a31/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a31/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a31/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a31/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a31/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a31/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a31/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a31/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a31/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a31/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a31/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/galaxy-a31/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />


        {/* Google Pixel */}
        <Route path="/pixel-mobile/select-Model" element={<ModelMobile selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/pixel-others/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pixel-others/other-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pixel-others/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-others/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-others/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-others/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-others/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-others/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-others/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-others/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-others/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-others/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-others/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-others/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-others/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-others/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-others/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-others/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-others/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/pixel-others/other-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Pixel */}
        <Route path="/pixel-8pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pixel-8pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Pixel */}
        <Route path="/pixel-8/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pixel-8/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-8/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Pixel */}
        <Route path="/pixel-7pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pixel-7pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Pixel */}
        <Route path="/pixel-7a/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pixel-7a/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7a/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7a/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7a/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7a/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7a/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7a/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7a/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7a/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7a/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7a/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7a/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7a/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7a/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7a/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7a/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7a/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Pixel */}
        <Route path="/pixel-7/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pixel-7/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-7/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Pixel */}
        <Route path="/pixel-6pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pixel-6pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Pixel */}
        <Route path="/pixel-6a/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pixel-6a/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6a/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6a/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6a/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6a/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6a/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6a/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6a/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6a/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6a/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6a/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6a/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6a/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6a/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6a/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6a/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6a/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Pixel */}
        <Route path="/pixel-6/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pixel-6/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-6/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Pixel */}
        <Route path="/pixel-5a/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pixel-5a/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5a/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5a/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5a/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5a/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5a/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5a/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5a/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5a/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5a/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5a/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5a/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5a/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5a/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5a/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5a/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5a/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Pixel */}
        <Route path="/pixel-5/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pixel-5/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-5/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Pixel */}
        <Route path="/pixel-4xl/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pixel-4xl/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4xl/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4xl/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4xl/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4xl/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4xl/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4xl/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4xl/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4xl/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4xl/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4xl/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4xl/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4xl/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4xl/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4xl/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4xl/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4xl/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Pixel */}
        <Route path="/pixel-4a/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pixel-4a/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4a/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4a/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4a/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4a/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4a/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4a/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4a/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4a/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4a/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4a/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4a/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4a/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4a/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4a/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4a/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4a/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Pixel */}
        <Route path="/pixel-4/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pixel-4/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-4/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Pixel */}
        <Route path="/pixel-3xl/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pixel-3xl/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3xl/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3xl/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3xl/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3xl/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3xl/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3xl/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3xl/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3xl/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3xl/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3xl/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3xl/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3xl/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3xl/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3xl/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3xl/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3xl/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Pixel */}
        <Route path="/pixel-3a/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pixel-3a/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3a/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3a/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3a/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3a/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3a/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3a/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3a/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3a/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3a/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3a/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3a/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3a/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3a/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3a/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3a/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3a/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Pixel */}
        <Route path="/pixel-3/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/pixel-3/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/pixel-3/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />


        {/* Poco */}
        <Route path="/poco-mobile/select-Model" element={<ModelMobile selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/poco-others/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-others/other-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-others/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-others/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-others/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-others/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-others/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-others/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-others/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-others/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-others/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-others/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-others/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-others/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-others/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-others/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-others/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-others/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-others/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/poco-others/other-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Poco Mobile */}
        <Route path="/poco-c40/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-c40/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c40/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c40/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c40/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c40/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c40/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c40/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c40/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c40/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c40/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c40/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c40/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c40/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c40/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c40/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c40/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c40/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-f3gt/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-f3gt/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3gt/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3gt/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3gt/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3gt/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3gt/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3gt/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3gt/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3gt/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3gt/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3gt/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3gt/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3gt/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3gt/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3gt/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3gt/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3gt/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-f5/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-f5/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-f5pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-f5pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f5pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-m2pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-m2pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-m5s/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-m5s/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5s/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5s/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5s/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5s/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5s/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5s/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5s/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5s/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5s/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5s/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5s/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5s/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5s/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5s/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5s/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5s/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-x4gt/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-x4gt/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4gt/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4gt/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4gt/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4gt/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4gt/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4gt/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4gt/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4gt/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4gt/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4gt/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4gt/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4gt/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4gt/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4gt/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4gt/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4gt/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-c3/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-c3/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c3/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c3/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c3/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c3/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c3/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c3/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c3/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c3/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c3/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c3/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c3/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c3/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c3/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c3/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c3/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-c3/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-f2pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-f2pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f2pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f2pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f2pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f2pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f2pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f2pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f2pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f2pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f2pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f2pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f2pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f2pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f2pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f2pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f2pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f2pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-f3/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-f3/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f3/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-f4/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-f4/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-f4gt/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-f4gt/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4gt/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4gt/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4gt/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4gt/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4gt/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4gt/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4gt/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4gt/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4gt/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4gt/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4gt/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4gt/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4gt/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4gt/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4gt/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-f4gt/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-m2/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-m2/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m2/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-m3/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-m3/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-m3pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-m3pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m3pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-m4/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-m4/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-m4pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-m4pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m4pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-m5/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-m5/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m5/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-m6/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-m6/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-m6pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-m6pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-m6pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-x2/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-x2/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x2/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x2/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x2/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x2/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x2/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x2/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x2/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x2/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x2/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x2/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x2/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x2/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x2/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x2/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x2/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x2/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-x3gt/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-x3gt/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3gt/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3gt/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3gt/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3gt/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3gt/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3gt/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3gt/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3gt/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3gt/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3gt/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3gt/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3gt/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3gt/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3gt/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3gt/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3gt/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-x3pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-x3pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x3pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-x4pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-x4pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x4pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-x5/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-x5/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Poco Mobile */}
        <Route path="/poco-x5pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/poco-x5pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/poco-x5pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />


        {/* Vivo */}
        <Route path="/vivo-mobile/select-Model" element={<ModelMobile selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/vivo-others/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivo-others/other-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivo-others/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-others/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-others/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-others/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-others/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-others/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-others/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-others/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-others/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-others/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-others/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-others/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-others/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-others/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-others/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-others/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-others/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/vivo-others/other-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Vivo Mobile */}
        <Route path="/vivo-y200pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivo-y200pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y200pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y200pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y200pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y200pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y200pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y200pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y200pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y200pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y200pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y200pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y200pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y200pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y200pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y200pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y200pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y200pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Vivo Mobile */}
        <Route path="/vivo-v40lite/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivo-v40lite/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v40lite/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v40lite/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v40lite/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v40lite/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v40lite/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v40lite/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v40lite/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v40lite/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v40lite/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v40lite/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v40lite/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v40lite/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v40lite/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v40lite/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v40lite/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v40lite/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Vivo Mobile */}
        <Route path="/vivo-x100ultra/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivo-x100ultra/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100ultra/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100ultra/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100ultra/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100ultra/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100ultra/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100ultra/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100ultra/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100ultra/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100ultra/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100ultra/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100ultra/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100ultra/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100ultra/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100ultra/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100ultra/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100ultra/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Vivo Mobile */}
        <Route path="/vivo-v29pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivo-v29pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v29pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v29pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v29pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v29pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v29pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v29pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v29pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v29pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v29pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v29pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v29pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v29pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v29pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v29pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v29pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v29pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Vivo Mobile */}
        <Route path="/vivo-v27pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivo-v27pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v27pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v27pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v27pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v27pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v27pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v27pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v27pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v27pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v27pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v27pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v27pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v27pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v27pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v27pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v27pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v27pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Vivo Mobile */}
        <Route path="/vivo-y36/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivo-y36/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y36/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y36/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y36/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y36/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y36/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y36/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y36/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y36/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y36/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y36/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y36/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y36/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y36/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y36/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y36/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y36/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Vivo Mobile */}
        <Route path="/vivo-x100pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivo-x100pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x100pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Vivo Mobile */}
        <Route path="/vivo-v25pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivo-v25pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v25pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v25pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v25pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v25pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v25pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v25pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v25pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v25pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v25pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v25pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v25pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v25pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v25pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v25pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v25pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v25pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Vivo Mobile */}
        <Route path="/vivo-x80pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivo-x80pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x80pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x80pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x80pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x80pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x80pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x80pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x80pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x80pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x80pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x80pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x80pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x80pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x80pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x80pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x80pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x80pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Vivo Mobile */}
        <Route path="/vivo-y75/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivo-y75/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y75/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y75/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y75/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y75/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y75/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y75/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y75/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y75/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y75/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y75/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y75/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y75/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y75/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y75/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y75/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y75/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Vivo Mobile */}
        <Route path="/vivo-y77/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivo-y77/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y77/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y77/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y77/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y77/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y77/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y77/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y77/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y77/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y77/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y77/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y77/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y77/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y77/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y77/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y77/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y77/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Vivo Mobile */}
        <Route path="/vivo-v21/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivo-v21/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v21/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v21/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v21/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v21/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v21/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v21/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v21/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v21/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v21/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v21/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v21/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v21/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v21/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v21/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v21/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v21/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Vivo Mobile */}
        <Route path="/vivo-x70pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivo-x70pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x70pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x70pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x70pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x70pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x70pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x70pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x70pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x70pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x70pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x70pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x70pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x70pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x70pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x70pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x70pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x70pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Vivo Mobile */}
        <Route path="/vivo-y33s/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivo-y33s/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y33s/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y33s/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y33s/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y33s/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y33s/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y33s/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y33s/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y33s/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y33s/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y33s/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y33s/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y33s/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y33s/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y33s/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y33s/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y33s/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Vivo Mobile */}
        <Route path="/vivo-y21/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivo-y21/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y21/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y21/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y21/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y21/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y21/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y21/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y21/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y21/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y21/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y21/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y21/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y21/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y21/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y21/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y21/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y21/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Vivo Mobile */}
        <Route path="/vivo-y51/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivo-y51/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y51/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y51/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y51/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y51/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y51/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y51/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y51/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y51/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y51/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y51/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y51/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y51/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y51/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y51/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y51/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y51/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Vivo Mobile */}
        <Route path="/vivo-v20/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivo-v20/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v20/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v20/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v20/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v20/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v20/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v20/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v20/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v20/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v20/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v20/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v20/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v20/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v20/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v20/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v20/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-v20/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Vivo Mobile */}
        <Route path="/vivo-x50pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivo-x50pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x50pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x50pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x50pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x50pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x50pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x50pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x50pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x50pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x50pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x50pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x50pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x50pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x50pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x50pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x50pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-x50pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Vivo Mobile */}
        <Route path="/vivo-y20/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/vivo-y20/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y20/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y20/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y20/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y20/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y20/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y20/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y20/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y20/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y20/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y20/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y20/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y20/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y20/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y20/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y20/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/vivo-y20/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />


        {/* Oppo */}
        <Route path="/oppo-mobile/select-Model" element={<ModelMobile selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/oppo-others/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-others/other-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-others/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-others/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-others/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-others/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-others/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-others/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-others/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-others/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-others/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-others/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-others/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-others/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-others/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-others/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-others/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-others/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-others/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/oppo-others/other-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Oppo Mobile */}
        <Route path="/oppo-a79/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-a79/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a79/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a79/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a79/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a79/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a79/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a79/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a79/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a79/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a79/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a79/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a79/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a79/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a79/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a79/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a79/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a79/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Oppo Mobile */}
        <Route path="/oppo-reno8pro+/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-reno8pro+/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno8pro+/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno8pro+/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno8pro+/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno8pro+/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno8pro+/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno8pro+/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno8pro+/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno8pro+/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno8pro+/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno8pro+/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno8pro+/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno8pro+/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno8pro+/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno8pro+/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno8pro+/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno8pro+/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Oppo Mobile */}
        <Route path="/oppo-a77/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-a77/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a77/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a77/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a77/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a77/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a77/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a77/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a77/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a77/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a77/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a77/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a77/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a77/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a77/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a77/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a77/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a77/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Oppo Mobile */}
        <Route path="/oppo-findx2pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-findx2pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx2pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx2pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx2pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx2pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx2pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx2pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx2pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx2pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx2pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx2pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx2pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx2pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx2pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx2pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx2pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx2pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Oppo Mobile */}
        <Route path="/oppo-reno4lite/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-reno4lite/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno4lite/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno4lite/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno4lite/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno4lite/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno4lite/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno4lite/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno4lite/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno4lite/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno4lite/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno4lite/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno4lite/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno4lite/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno4lite/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno4lite/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno4lite/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-reno4lite/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Oppo Mobile */}
        <Route path="/oppo-findx3neo/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-findx3neo/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3neo/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3neo/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3neo/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3neo/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3neo/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3neo/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3neo/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3neo/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3neo/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3neo/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3neo/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3neo/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3neo/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3neo/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3neo/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3neo/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Oppo Mobile */}
        <Route path="/oppo-findx5lite/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-findx5lite/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx5lite/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx5lite/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx5lite/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx5lite/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx5lite/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx5lite/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx5lite/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx5lite/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx5lite/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx5lite/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx5lite/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx5lite/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx5lite/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx5lite/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx5lite/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx5lite/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Oppo Mobile */}
        <Route path="/oppo-a31/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-a31/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a31/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a31/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a31/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a31/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a31/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a31/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a31/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a31/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a31/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a31/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a31/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a31/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a31/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a31/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a31/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a31/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Oppo Mobile */}
        <Route path="/oppo-a52/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-a52/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a52/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a52/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a52/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a52/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a52/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a52/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a52/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a52/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a52/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a52/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a52/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a52/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a52/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a52/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a52/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a52/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Oppo Mobile */}
        <Route path="/oppo-a53s/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-a53s/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a53s/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a53s/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a53s/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a53s/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a53s/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a53s/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a53s/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a53s/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a53s/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a53s/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a53s/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a53s/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a53s/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a53s/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a53s/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a53s/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Oppo Mobile */}
        <Route path="/oppo-a54/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-a54/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a54/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a54/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a54/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a54/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a54/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a54/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a54/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a54/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a54/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a54/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a54/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a54/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a54/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a54/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a54/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a54/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Oppo Mobile */}
        <Route path="/oppo-a55/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-a55/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Oppo Mobile */}
        <Route path="/oppo-a55s/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-a55s/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55s/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55s/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55s/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55s/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55s/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55s/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55s/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55s/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55s/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55s/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55s/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55s/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55s/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55s/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55s/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a55s/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Oppo Mobile */}
        <Route path="/oppo-a57/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-a57/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a57/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a57/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a57/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a57/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a57/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a57/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a57/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a57/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a57/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a57/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a57/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a57/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a57/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a57/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a57/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a57/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Oppo Mobile */}
        <Route path="/oppo-a74/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-a74/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a74/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a74/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a74/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a74/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a74/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a74/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a74/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a74/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a74/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a74/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a74/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a74/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a74/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a74/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a74/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a74/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Oppo Mobile */}
        <Route path="/oppo-a76/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-a76/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a76/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a76/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a76/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a76/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a76/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a76/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a76/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a76/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a76/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a76/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a76/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a76/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a76/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a76/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a76/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a76/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Oppo Mobile */}
        <Route path="/oppo-a78/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-a78/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a78/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a78/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a78/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a78/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a78/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a78/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a78/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a78/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a78/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a78/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a78/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a78/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a78/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a78/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a78/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a78/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Oppo Mobile */}
        <Route path="/oppo-a94/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-a94/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a94/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a94/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a94/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a94/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a94/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a94/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a94/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a94/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a94/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a94/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a94/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a94/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a94/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a94/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a94/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-a94/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Oppo Mobile */}
        <Route path="/oppo-findx3lite/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-findx3lite/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3lite/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3lite/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3lite/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3lite/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3lite/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3lite/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3lite/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3lite/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3lite/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3lite/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3lite/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3lite/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3lite/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3lite/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3lite/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx3lite/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Oppo Mobile */}
        <Route path="/oppo-findx/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-findx/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Oppo Mobile */}
        <Route path="/oppo-findx6pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oppo-findx6pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx6pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx6pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx6pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx6pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx6pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx6pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx6pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx6pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx6pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx6pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx6pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx6pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx6pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx6pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx6pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/oppo-findx6pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />


        {/* RealMe */}
        <Route path="/realme-mobile/select-Model" element={<ModelMobile selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/realme-others/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-others/other-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-others/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-others/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-others/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-others/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-others/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-others/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-others/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-others/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-others/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-others/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-others/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-others/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-others/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-others/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-others/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-others/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-others/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-others/other-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* RealMe Mobile */}
        <Route path="/realme-gt2/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-gt2/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* RealMe Mobile */}
        <Route path="/realme-gt5/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-gt5/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* RealMe Mobile */}
        <Route path="/realme-gtmaster-edition/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-gtmaster-edition/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtmaster-edition/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtmaster-edition/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtmaster-edition/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtmaster-edition/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtmaster-edition/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtmaster-edition/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtmaster-edition/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtmaster-edition/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtmaster-edition/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtmaster-edition/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtmaster-edition/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtmaster-edition/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtmaster-edition/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtmaster-edition/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtmaster-edition/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtmaster-edition/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* RealMe Mobile */}
        <Route path="/realme-gtexplorer-edition/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-gtexplorer-edition/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtexplorer-edition/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtexplorer-edition/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtexplorer-edition/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtexplorer-edition/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtexplorer-edition/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtexplorer-edition/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtexplorer-edition/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtexplorer-edition/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtexplorer-edition/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtexplorer-edition/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtexplorer-edition/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtexplorer-edition/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtexplorer-edition/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtexplorer-edition/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtexplorer-edition/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gtexplorer-edition/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* RealMe Mobile */}
        <Route path="/realme-gt5pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-gt5pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt5pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* RealMe Mobile */}
        <Route path="/realme-gt2pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-gt2pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-gt2pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* RealMe Mobile */}
        <Route path="/realme-x7pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-x7pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* RealMe Mobile */}
        <Route path="/realme-x7max/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-x7max/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7max/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7max/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7max/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7max/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7max/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7max/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7max/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7max/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7max/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7max/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7max/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7max/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7max/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7max/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7max/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7max/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* RealMe Mobile */}
        <Route path="/realme-x7/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-x7/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x7/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* RealMe Mobile */}
        <Route path="/realme-x50pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-x50pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* RealMe Mobile */}
        <Route path="/realme-x50/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-x50/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-x50/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* RealMe Mobile */}
        <Route path="/realme-narzo50pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-narzo50pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* RealMe Mobile */}
        <Route path="/realme-narzo50/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-narzo50/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo50/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* RealMe Mobile */}
        <Route path="/realme-narzo10pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-narzo10pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* RealMe Mobile */}
        <Route path="/realme-narzo10/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-narzo10/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo10/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* RealMe Mobile */}
        <Route path="/realme-narzo70/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-narzo70/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo70/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo70/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo70/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo70/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo70/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo70/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo70/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo70/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo70/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo70/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo70/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo70/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo70/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo70/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo70/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo70/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* RealMe Mobile */}
        <Route path="/realme-narzo60pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-narzo60pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* RealMe Mobile */}
        <Route path="/realme-narzo60/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-narzo60/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo60/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* RealMe Mobile */}
        <Route path="/realme-narzo30pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-narzo30pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* RealMe Mobile */}
        <Route path="/realme-narzo30/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-narzo30/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/realme-narzo30/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />


        {/* Redmi */}
        <Route path="/redmi-mobile/select-Model" element={<ModelMobile selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/redmi-others/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-others/other-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-others/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-others/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-others/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-others/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-others/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-others/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-others/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-others/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-others/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-others/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-others/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-others/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-others/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-others/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-others/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-others/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-others/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-others/other-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Redmi Mobile */}
        <Route path="/redmi-note-13pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-note-13pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Redmi Mobile */}
        <Route path="/redmi-note-13pro+/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-note-13pro+/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro+/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro+/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro+/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro+/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro+/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro+/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro+/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro+/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro+/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro+/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro+/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro+/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro+/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro+/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro+/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13pro+/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Redmi Mobile */}
        <Route path="/redmi-note-13/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-note-13/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-13/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Redmi Mobile */}
        <Route path="/redmi-13/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-13/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-13/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-13/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-13/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-13/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-13/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-13/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-13/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-13/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-13/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-13/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-13/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-13/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-13/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-13/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-13/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-13/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Redmi Mobile */}
        <Route path="/redmi-note-12pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-note-12pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Redmi Mobile */}
        <Route path="/redmi-note-12pro+/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-note-12pro+/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro+/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro+/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro+/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro+/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro+/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro+/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro+/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro+/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro+/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro+/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro+/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro+/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro+/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro+/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro+/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12pro+/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Redmi Mobile */}
        <Route path="/redmi-note-12/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-note-12/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-12/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Redmi Mobile */}
        <Route path="/redmi-12c/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-12c/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-12c/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-12c/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-12c/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-12c/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-12c/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-12c/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-12c/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-12c/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-12c/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-12c/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-12c/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-12c/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-12c/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-12c/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-12c/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-12c/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Redmi Mobile */}
        <Route path="/redmi-note-11pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-note-11pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Redmi Mobile */}
        <Route path="/redmi-note-11pro+/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-note-11pro+/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro+/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro+/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro+/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro+/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro+/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro+/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro+/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro+/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro+/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro+/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro+/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro+/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro+/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro+/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro+/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11pro+/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Redmi Mobile */}
        <Route path="/redmi-note-11/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-note-11/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-11/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Redmi Mobile */}
        <Route path="/redmi-10c/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-10c/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-10c/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-10c/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-10c/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-10c/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-10c/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-10c/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-10c/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-10c/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-10c/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-10c/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-10c/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-10c/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-10c/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-10c/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-10c/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-10c/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Redmi Mobile */}
        <Route path="/redmi-note-10pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-note-10pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Redmi Mobile */}
        <Route path="/redmi-note-10promax/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-note-10promax/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10promax/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10promax/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10promax/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10promax/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10promax/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10promax/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10promax/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10promax/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10promax/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10promax/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10promax/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10promax/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10promax/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10promax/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10promax/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10promax/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Redmi Mobile */}
        <Route path="/redmi-note-10/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-note-10/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-10/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Redmi Mobile */}
        <Route path="/redmi-9power/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-9power/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9power/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9power/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9power/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9power/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9power/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9power/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9power/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9power/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9power/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9power/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9power/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9power/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9power/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9power/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9power/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9power/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Redmi Mobile */}
        <Route path="/redmi-note-9pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-note-9pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Redmi Mobile */}
        <Route path="/redmi-note-9promax/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-note-9promax/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9promax/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9promax/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9promax/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9promax/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9promax/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9promax/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9promax/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9promax/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9promax/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9promax/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9promax/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9promax/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9promax/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9promax/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9promax/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9promax/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Redmi Mobile */}
        <Route path="/redmi-note-9/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-note-9/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-note-9/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Redmi Mobile */}
        <Route path="/redmi-9prime/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-9prime/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9prime/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9prime/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9prime/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9prime/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9prime/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9prime/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9prime/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9prime/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9prime/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9prime/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9prime/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9prime/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9prime/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9prime/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9prime/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/redmi-9prime/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />


        {/* Motorola */}
        <Route path="/motorola-mobile/select-Model" element={<ModelMobile selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/moto-others/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-others/other-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-others/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-others/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-others/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-others/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-others/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-others/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-others/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-others/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-others/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-others/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-others/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-others/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-others/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-others/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-others/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-others/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-others/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/moto-others/other-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Moto */}
        <Route path="/moto-e13/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-e13/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e13/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e13/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e13/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e13/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e13/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e13/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e13/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e13/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e13/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e13/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e13/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e13/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e13/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e13/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e13/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e13/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-e20/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-e20/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e20/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e20/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e20/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e20/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e20/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e20/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e20/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e20/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e20/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e20/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e20/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e20/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e20/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e20/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e20/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e20/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-e32/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-e32/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e32/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e32/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e32/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e32/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e32/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e32/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e32/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e32/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e32/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e32/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e32/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e32/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e32/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e32/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e32/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e32/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-e7/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-e7/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e7/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e7/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e7/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e7/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e7/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e7/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e7/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e7/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e7/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e7/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e7/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e7/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e7/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e7/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e7/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e7/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-e30/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-e30/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e30/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e30/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e30/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e30/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e30/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e30/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e30/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e30/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e30/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e30/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e30/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e30/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e30/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e30/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e30/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-e30/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-g40fusion/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-g40fusion/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40fusion/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40fusion/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40fusion/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40fusion/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40fusion/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40fusion/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40fusion/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40fusion/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40fusion/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40fusion/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40fusion/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40fusion/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40fusion/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40fusion/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40fusion/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40fusion/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-g40/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-g40/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g40/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-g13/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-g13/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g13/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g13/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g13/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g13/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g13/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g13/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g13/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g13/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g13/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g13/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g13/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g13/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g13/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g13/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g13/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g13/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-g23/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-g23/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g23/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g23/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g23/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g23/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g23/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g23/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g23/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g23/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g23/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g23/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g23/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g23/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g23/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g23/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g23/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g23/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-g32/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-g32/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g32/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g32/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g32/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g32/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g32/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g32/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g32/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g32/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g32/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g32/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g32/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g32/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g32/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g32/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g32/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g32/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-g50/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-g50/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g50/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g50/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g50/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g50/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g50/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g50/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g50/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g50/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g50/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g50/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g50/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g50/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g50/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g50/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g50/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g50/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-g60/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-g60/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g60/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g60/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g60/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g60/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g60/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g60/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g60/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g60/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g60/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g60/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g60/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g60/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g60/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g60/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g60/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g60/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-g62/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-g62/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g62/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g62/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g62/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g62/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g62/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g62/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g62/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g62/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g62/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g62/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g62/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g62/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g62/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g62/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g62/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g62/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-g72/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-g72/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g72/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g72/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g72/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g72/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g72/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g72/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g72/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g72/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g72/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g72/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g72/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g72/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g72/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g72/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g72/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g72/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-g53/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-g53/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g53/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g53/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g53/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g53/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g53/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g53/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g53/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g53/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g53/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g53/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g53/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g53/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g53/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g53/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g53/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g53/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-g84/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-g84/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g84/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g84/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g84/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g84/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g84/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g84/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g84/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g84/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g84/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g84/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g84/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g84/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g84/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g84/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g84/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g84/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-g73/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-g73/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g73/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g73/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g73/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g73/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g73/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g73/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g73/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g73/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g73/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g73/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g73/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g73/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g73/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g73/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g73/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-g73/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-edge+/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-edge+/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge+/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge+/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge+/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge+/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge+/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge+/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge+/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge+/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge+/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge+/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge+/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge+/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge+/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge+/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge+/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge+/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-edge20/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-edge20/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-edge30/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-edge30/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-edge20lite/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-edge20lite/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20lite/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20lite/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20lite/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20lite/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20lite/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20lite/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20lite/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20lite/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20lite/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20lite/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20lite/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20lite/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20lite/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20lite/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20lite/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20lite/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-edge20pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-edge20pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge20pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-edge30fusion/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-edge30fusion/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30fusion/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30fusion/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30fusion/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30fusion/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30fusion/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30fusion/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30fusion/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30fusion/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30fusion/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30fusion/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30fusion/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30fusion/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30fusion/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30fusion/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30fusion/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30fusion/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-edge30neo/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-edge30neo/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30neo/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30neo/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30neo/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30neo/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30neo/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30neo/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30neo/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30neo/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30neo/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30neo/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30neo/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30neo/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30neo/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30neo/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30neo/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30neo/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-edge30pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-edge30pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-edge30ultra/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-edge30ultra/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30ultra/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30ultra/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30ultra/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30ultra/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30ultra/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30ultra/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30ultra/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30ultra/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30ultra/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30ultra/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30ultra/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30ultra/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30ultra/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30ultra/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30ultra/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge30ultra/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-edge40/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-edge40/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Moto */}
        <Route path="/moto-edge40pro/select-issue" element={<IssueMobile selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/moto-edge40pro/battery-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40pro/camera-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40pro/charging-port/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40pro/ear-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40pro/loud-speaker/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40pro/back-panel/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40pro/heating-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40pro/microphone-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40pro/motherboard-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40pro/mute-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40pro/software-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40pro/power-button/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40pro/passcode-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40pro/screen-replacement/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40pro/touchid/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40pro/water-damage/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} /> 
        <Route path="/moto-edge40pro/wifi-issue/price-details" element={<PriceDetailsMobile selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />



        {/* SmartWatch */}
        <Route path="/smartwatch/select-brand" element={<SmartWatch setSelectedBrand={setSelectedBrand} />} />
        {/* Apple */}
        <Route path="/smartwatch/apple/select-Model" element={<ModelWatch />} />

        {/* Others */}
        <Route path="/iwatch-others/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-others/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-others/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-others/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-others/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-others/other-issue/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch Series 0 */}
        <Route path="/iWatch-series0-38/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-series0-38/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series0-38/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series0-38/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series0-38/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch Series 0 */}
        <Route path="/iWatch-series0-42/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-series0-42/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series0-42/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series0-42/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series0-42/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch Series 1 */}
        <Route path="/iWatch-series1-38/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-series1-38/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series1-38/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series1-38/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series1-38/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch Series 1 */}
        <Route path="/iWatch-series1-42/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-series1-42/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series1-42/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series1-42/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series1-42/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch Series 2 */}
        <Route path="/iWatch-series2-38/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-series2-38/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series2-38/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series2-38/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series2-38/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch Series 2 */}
        <Route path="/iWatch-series2-42/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-series2-42/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series2-42/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series2-42/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series2-42/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch Series 3 */}
        <Route path="/iWatch-series3-38/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-series3-38/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series3-38/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series3-38/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series3-38/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch Series 3 */}
        <Route path="/iWatch-series3-42/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-series3-42/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series3-42/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series3-42/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series3-42/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch Series 4 */}
        <Route path="/iWatch-series4-40/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-series4-40/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series4-40/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series4-40/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series4-40/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch Series 4 */}
        <Route path="/iWatch-series4-44/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-series4-44/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series4-44/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series4-44/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series4-44/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch Series 5 */}
        <Route path="/iWatch-series5-40/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-series5-40/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series5-40/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series5-40/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series5-40/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch Series 5 */}
        <Route path="/iWatch-series5-44/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-series5-44/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series5-44/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series5-44/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series5-44/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch Series 6 */}
        <Route path="/iWatch-series6-40/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-series6-40/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series6-40/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series6-40/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series6-40/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch Series 6 */}
        <Route path="/iWatch-series6-44/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-series6-44/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series6-44/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series6-44/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series6-44/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch Series 7 */}
        <Route path="/iWatch-series7-41/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-series7-41/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series7-41/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series7-41/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series7-41/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch Series 7 */}
        <Route path="/iWatch-series7-45/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-series7-45/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series7-45/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series7-45/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series7-45/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch Series 8 */}
        <Route path="/iWatch-series8-41/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-series8-41/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series8-41/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series8-41/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series8-41/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch Series 8 */}
        <Route path="/iWatch-series8-45/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-series8-45/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series8-45/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series8-45/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-series8-45/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch SE 1st gen */}
        <Route path="/iWatch-SE1-40/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-se1-40/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-se1-40/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-se1-40/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-se1-40/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch SE 1st gen */}
        <Route path="/iWatch-SE1-44/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-se1-44/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-se1-44/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-se1-44/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-se1-44/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch SE 2nd gen */}
        <Route path="/iWatch-SE2-40/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-se2-40/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-se2-40/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-se2-40/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-se2-40/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch SE 2nd gen */}
        <Route path="/iWatch-SE2-44/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-se2-44/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-se2-44/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-se2-44/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-se2-44/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch Ultra */}
        <Route path="/iWatch-Ultra/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-ultra/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-ultra/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-ultra/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-ultra/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iWatch Ultra 2 */}
        <Route path="/iWatch-Ultra-2/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/iwatch-ultra-2/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-ultra-2/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-ultra-2/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/iwatch-ultra-2/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* OnePlus */}
        <Route path="/smartwatch/oneplus/select-Model" element={<ModelWatch selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/oneplus-watch-others/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-watch-others/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/oneplus-watch-others/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/oneplus-watch-others/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/oneplus-watch-others/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/oneplus-watch-others/other-issue/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* OnePlus Watch 2 */}
        <Route path="/Oneplus-Watch-2/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-watch-2/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/oneplus-watch-2/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/oneplus-watch-2/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/oneplus-watch-2/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Watch 2 */}
        <Route path="/Oneplus-Watch-2R/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-watch-2r/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/oneplus-watch-2r/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/oneplus-watch-2r/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/oneplus-watch-2r/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Watch 2 */}
        <Route path="/Oneplus-Watch/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-watch/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/oneplus-watch/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/oneplus-watch/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/oneplus-watch/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Watch 2 */}
        <Route path="/Oneplus-Watch-SE/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-watch-se/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/oneplus-watch-se/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/oneplus-watch-se/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/oneplus-watch-se/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* OnePlus Watch 2 */}
        <Route path="/Oneplus-Watch-Pro/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/oneplus-watch-pro/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/oneplus-watch-pro/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/oneplus-watch-pro/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/oneplus-watch-pro/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Samsung */}
        <Route path="/smartwatch/samsung/select-Model" element={<ModelWatch selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Others */}
        <Route path="/samsung-watch-others/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/samsung-watch-others/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/samsung-watch-others/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/samsung-watch-others/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/samsung-watch-others/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/samsung-watch-others/other-issue/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Galaxy Watch 7 */}
        <Route path="/Galaxy-Watch-7/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-watch-7/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-7/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-7/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-7/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Galaxy Watch 7 */}
        <Route path="/Galaxy-Watch-7-Classic/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-watch-7-classic/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-7-classic/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-7-classic/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-7-classic/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Galaxy Watch 7 */}
        <Route path="/Galaxy-Watch-Ultra/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-watch-ultra/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-ultra/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-ultra/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-ultra/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Galaxy Watch 7 */}
        <Route path="/Galaxy-Watch-6/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-watch-6/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-6/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-6/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-6/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Galaxy Watch 7 */}
        <Route path="/Galaxy-Watch-6-Classic/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-watch-6-classic/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-6-classic/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-6-classic/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-6-classic/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Galaxy Watch 7 */}
        <Route path="/Galaxy-Watch-5/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-watch-5/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-5/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-5/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-5/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Galaxy Watch 7 */}
        <Route path="/Galaxy-Watch-5Pro/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-watch-5pro/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-5pro/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-5pro/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-5pro/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Galaxy Watch 7 */}
        <Route path="/Galaxy-Watch-4/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-watch-4/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-4/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-4/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-4/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Galaxy Watch 7 */}
        <Route path="/Galaxy-Watch-4-Classic/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-watch-4-classic/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-4-classic/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-4-classic/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch-4-classic/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        {/* Galaxy Watch 7 */}
        <Route path="/Galaxy-Watch/select-issue" element={<IssueWatch selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-watch/battery-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch/not-charging/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch/not-turning-on/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-watch/screen-replacement/price-details" element={<PriceDetailsWatch selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Tablet */}
        <Route path="/tablet/select-brand" element={<Tablet setSelectedBrand={setSelectedBrand} />} />

        {/* Apple */}
        <Route path="/apple-ipad/select-Model" element={<ModelTablet selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* iPad 1st Gen */}
        <Route path="/ipad-1gen/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-1gen/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-1gen/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-1gen/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-1gen/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-1gen/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-1gen/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-1gen/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-1gen/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-1gen/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-1gen/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-1gen/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-1gen/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-1gen/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-1gen/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-1gen/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-1gen/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iPad 2 */}
        <Route path="/ipad-2gen/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-2gen/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-2gen/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-2gen/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-2gen/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-2gen/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-2gen/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-2gen/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-2gen/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-2gen/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-2gen/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-2gen/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-2gen/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-2gen/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-2gen/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-2gen/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-2gen/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iPad 3rd Gen */}
        <Route path="/ipad-3gen/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-3gen/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-3gen/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-3gen/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-3gen/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-3gen/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-3gen/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-3gen/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-3gen/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-3gen/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-3gen/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-3gen/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-3gen/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-3gen/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-3gen/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-3gen/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-3gen/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iPad 4 Gen */}
        <Route path="/ipad-4gen/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-4gen/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-4gen/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-4gen/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-4gen/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-4gen/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-4gen/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-4gen/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-4gen/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-4gen/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-4gen/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-4gen/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-4gen/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-4gen/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-4gen/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-4gen/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-4gen/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iPad 5 Gen */}
        <Route path="/ipad-5gen/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-5gen/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-5gen/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-5gen/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-5gen/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-5gen/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-5gen/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-5gen/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-5gen/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-5gen/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-5gen/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-5gen/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-5gen/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-5gen/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-5gen/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-5gen/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-5gen/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iPad 6 Gen */}
        <Route path="/ipad-6gen/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-6gen/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-6gen/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-6gen/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-6gen/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-6gen/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-6gen/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-6gen/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-6gen/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-6gen/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-6gen/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-6gen/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-6gen/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-6gen/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-6gen/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-6gen/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-6gen/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iPad 7 Gen */}
        <Route path="/ipad-7gen/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-7gen/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-7gen/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-7gen/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-7gen/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-7gen/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-7gen/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-7gen/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-7gen/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-7gen/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-7gen/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-7gen/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-7gen/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-7gen/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-7gen/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-7gen/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-7gen/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iPad 8 Gen */}
        <Route path="/ipad-8gen/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-8gen/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-8gen/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-8gen/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-8gen/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-8gen/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-8gen/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-8gen/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-8gen/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-8gen/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-8gen/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-8gen/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-8gen/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-8gen/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-8gen/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-8gen/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-8gen/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iPad 9 Gen */}
        <Route path="/ipad-9gen/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-9gen/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-9gen/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-9gen/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-9gen/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-9gen/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-9gen/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-9gen/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-9gen/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-9gen/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-9gen/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-9gen/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-9gen/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-9gen/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-9gen/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-9gen/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-9gen/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iPad 10 Gen */}
        <Route path="/ipad-10gen/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-10gen/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-10gen/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-10gen/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-10gen/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-10gen/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-10gen/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-10gen/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-10gen/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-10gen/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-10gen/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-10gen/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-10gen/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-10gen/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-10gen/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-10gen/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-10gen/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iPad Mini 1 Gen */}
        <Route path="/ipad-mini-1gen/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-mini-1gen/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-1gen/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-1gen/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-1gen/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-1gen/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-1gen/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-1gen/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-1gen/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-1gen/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-1gen/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-1gen/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-1gen/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-1gen/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-1gen/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-1gen/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-1gen/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iPad Mini 2 */}
        <Route path="/ipad-mini-2/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-mini-2/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-2/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-2/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-2/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-2/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-2/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-2/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-2/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-2/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-2/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-2/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-2/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-2/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-2/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-2/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-2/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iPad Mini 3 */}
        <Route path="/ipad-mini-3/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-mini-3/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-3/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-3/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-3/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-3/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-3/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-3/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-3/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-3/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-3/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-3/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-3/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-3/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-3/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-3/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-3/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iPad Mini 4 */}
        <Route path="/ipad-mini-4/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-mini-4/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-4/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-4/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-4/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-4/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-4/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-4/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-4/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-4/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-4/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-4/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-4/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-4/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-4/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-4/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-4/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iPad Mini 5 Gen */}
        <Route path="/ipad-mini-5gen/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-mini-5gen/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-5gen/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-5gen/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-5gen/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-5gen/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-5gen/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-5gen/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-5gen/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-5gen/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-5gen/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-5gen/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-5gen/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-5gen/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-5gen/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-5gen/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-5gen/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iPad Mini 6 Gen */}
        <Route path="/ipad-mini-6gen/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-mini-6gen/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-6gen/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-6gen/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-6gen/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-6gen/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-6gen/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-6gen/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-6gen/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-6gen/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-6gen/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-6gen/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-6gen/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-6gen/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-6gen/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-6gen/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-mini-6gen/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iPad Air 1 Gen */}
        <Route path="/ipad-air-1gen/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-air-1gen/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-1gen/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-1gen/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-1gen/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-1gen/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-1gen/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-1gen/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-1gen/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-1gen/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-1gen/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-1gen/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-1gen/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-1gen/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-1gen/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-1gen/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-1gen/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* iPad Air 2 */}
        <Route path="/ipad-air-2/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-air-2/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-2/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-2/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-2/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-2/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-2/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-2/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-2/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-2/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-2/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-2/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-2/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-2/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-2/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-2/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-2/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        

        {/* iPad Air 3 Gen */}
        <Route path="/ipad-air-3gen/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-air-3gen/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-3gen/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-3gen/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-3gen/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-3gen/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-3gen/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-3gen/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-3gen/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-3gen/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-3gen/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-3gen/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-3gen/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-3gen/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-3gen/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-3gen/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-3gen/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        

        {/* iPad Air 4 Gen */}
        <Route path="/ipad-air-4gen/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-air-4gen/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-4gen/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-4gen/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-4gen/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-4gen/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-4gen/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-4gen/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-4gen/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-4gen/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-4gen/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-4gen/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-4gen/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-4gen/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-4gen/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-4gen/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-4gen/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        

        {/* iPad Air 5 Gen */}
        <Route path="/ipad-air-5gen/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-air-5gen/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-5gen/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-5gen/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-5gen/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-5gen/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-5gen/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-5gen/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-5gen/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-5gen/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-5gen/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-5gen/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-5gen/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-5gen/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-5gen/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-5gen/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-air-5gen/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        

        {/* iPad Pro 9.7 */}
        <Route path="/ipad-pro-9.7/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-pro-9.7/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-9.7/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-9.7/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-9.7/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-9.7/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-9.7/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-9.7/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-9.7/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-9.7/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-9.7/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-9.7/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-9.7/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-9.7/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-9.7/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-9.7/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-9.7/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        

        {/* iPad Pro 10.5 */}
        <Route path="/ipad-pro-10.5/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-pro-10.5/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-10.5/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-10.5/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-10.5/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-10.5/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-10.5/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-10.5/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-10.5/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-10.5/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-10.5/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-10.5/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-10.5/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-10.5/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-10.5/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-10.5/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-10.5/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        

        {/* iPad Pro 1gen11 */}
        <Route path="/ipad-pro-1gen11/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-pro-1gen11/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen11/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen11/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen11/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen11/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen11/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen11/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen11/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen11/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen11/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen11/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen11/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen11/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen11/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen11/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen11/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        

        {/* iPad Pro 2gen11 */}
        <Route path="/ipad-pro-2gen11/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-pro-2gen11/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen11/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen11/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen11/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen11/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen11/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen11/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen11/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen11/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen11/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen11/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen11/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen11/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen11/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen11/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen11/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        

        {/* iPad Pro 3gen11 */}
        <Route path="/ipad-pro-3gen11/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-pro-3gen11/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen11/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen11/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen11/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen11/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen11/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen11/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen11/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen11/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen11/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen11/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen11/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen11/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen11/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen11/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen11/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        

        {/* iPad Pro 4gen11 */}
        <Route path="/ipad-pro-4gen11/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-pro-4gen11/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen11/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen11/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen11/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen11/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen11/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen11/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen11/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen11/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen11/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen11/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen11/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen11/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen11/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen11/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen11/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        

        {/* iPad Pro 1gen12.9 */}
        <Route path="/ipad-pro-1gen12.9/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-pro-1gen12.9/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen12.9/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen12.9/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen12.9/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen12.9/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen12.9/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen12.9/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen12.9/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen12.9/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen12.9/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen12.9/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen12.9/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen12.9/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen12.9/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen12.9/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-1gen12.9/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        

        {/* iPad Pro 2gen12.9 */}
        <Route path="/ipad-pro-2gen12.9/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-pro-2gen12.9/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen12.9/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen12.9/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen12.9/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen12.9/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen12.9/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen12.9/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen12.9/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen12.9/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen12.9/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen12.9/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen12.9/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen12.9/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen12.9/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen12.9/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-2gen12.9/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        

        {/* iPad Pro 3gen12.9 */}
        <Route path="/ipad-pro-3gen12.9/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-pro-3gen12.9/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen12.9/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen12.9/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen12.9/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen12.9/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen12.9/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen12.9/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen12.9/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen12.9/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen12.9/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen12.9/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen12.9/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen12.9/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen12.9/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen12.9/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-3gen12.9/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        

        {/* iPad Pro 4gen12.9 */}
        <Route path="/ipad-pro-4gen12.9/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-pro-4gen12.9/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen12.9/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen12.9/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen12.9/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen12.9/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen12.9/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen12.9/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen12.9/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen12.9/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen12.9/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen12.9/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen12.9/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen12.9/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen12.9/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen12.9/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-4gen12.9/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        

        {/* iPad Pro 5gen12.9 */}
        <Route path="/ipad-pro-5gen12.9/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-pro-5gen12.9/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-5gen12.9/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-5gen12.9/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-5gen12.9/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-5gen12.9/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-5gen12.9/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-5gen12.9/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-5gen12.9/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-5gen12.9/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-5gen12.9/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-5gen12.9/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-5gen12.9/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-5gen12.9/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-5gen12.9/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-5gen12.9/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-5gen12.9/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        

        {/* iPad Pro 6gen12.9 */}
        <Route path="/ipad-pro-6gen12.9/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/ipad-pro-6gen12.9/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-6gen12.9/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-6gen12.9/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-6gen12.9/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-6gen12.9/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-6gen12.9/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-6gen12.9/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-6gen12.9/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-6gen12.9/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-6gen12.9/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-6gen12.9/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-6gen12.9/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-6gen12.9/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-6gen12.9/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-6gen12.9/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/ipad-pro-6gen12.9/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        



        {/* Redmi */}
        <Route path="/redmi-pad/select-Model" element={<ModelTablet selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Redmi Pad */}
        <Route path="/redmi-pad/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-pad/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Redmi Pad SE */}
        <Route path="/redmi-pad-se/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-pad-se/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-se/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-se/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-se/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-se/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-se/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-se/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-se/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-se/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-se/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-se/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-se/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-se/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-se/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-se/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-se/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Redmi Pad Pro */}
        <Route path="/redmi-pad-pro/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-pad-pro/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Redmi Pad Pro 5g */}
        <Route path="/redmi-pad-pro5g/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/redmi-pad-pro5g/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro5g/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro5g/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro5g/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro5g/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro5g/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro5g/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro5g/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro5g/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro5g/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro5g/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro5g/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro5g/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro5g/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro5g/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/redmi-pad-pro5g/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* RealMe */}
        <Route path="/realme-pad/select-Model" element={<ModelTablet selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* RealMe Pad */}
        <Route path="/realme-pad/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-pad/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* RealMe Pad X */}
        <Route path="/realme-pad-x/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-pad-x/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-x/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-x/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-x/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-x/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-x/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-x/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-x/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-x/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-x/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-x/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-x/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-x/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-x/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-x/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-x/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* RealMe Pad Mini */}
        <Route path="/realme-pad-mini/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-pad-mini/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-mini/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-mini/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-mini/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-mini/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-mini/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-mini/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-mini/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-mini/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-mini/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-mini/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-mini/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-mini/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-mini/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-mini/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-mini/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* RealMe Pad 2 */}
        <Route path="/realme-pad-2/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-pad-2/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* RealMe Pad 2 Lite */}
        <Route path="/realme-pad-2lite/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/realme-pad-2lite/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2lite/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2lite/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2lite/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2lite/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2lite/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2lite/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2lite/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2lite/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2lite/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2lite/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2lite/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2lite/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2lite/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2lite/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/realme-pad-2lite/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Samsung */}
        <Route path="/galaxy-tab/select-Model" element={<ModelTablet selectedBrand={selectedBrand} setSelectedModel={setSelectedModel}/>} />

        {/* Galaxy Tab S9 Ultra */}
        <Route path="/galaxy-tab-s9ultra/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-tab-s9ultra/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9ultra/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9ultra/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9ultra/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9ultra/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9ultra/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9ultra/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9ultra/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9ultra/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9ultra/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9ultra/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9ultra/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9ultra/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9ultra/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9ultra/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9ultra/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Galaxy Tab S8 Ultra */}
        <Route path="/galaxy-tab-s8ultra/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-tab-s8ultra/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8ultra/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8ultra/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8ultra/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8ultra/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8ultra/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8ultra/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8ultra/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8ultra/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8ultra/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8ultra/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8ultra/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8ultra/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8ultra/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8ultra/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8ultra/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Galaxy Tab S9 */}
        <Route path="/galaxy-tab-s9/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-tab-s9/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s9/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Galaxy Tab S8+ */}
        <Route path="/galaxy-tab-s8+/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-tab-s8+/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8+/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8+/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8+/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8+/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8+/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8+/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8+/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8+/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8+/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8+/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8+/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8+/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8+/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8+/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8+/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Galaxy Tab S7+ */}
        <Route path="/galaxy-tab-s7+/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-tab-s7+/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7+/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7+/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7+/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7+/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7+/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7+/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7+/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7+/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7+/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7+/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7+/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7+/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7+/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7+/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7+/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Galaxy Tab S8 */}
        <Route path="/galaxy-tab-s8/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-tab-s8/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s8/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Galaxy Tab S7 FE */}
        <Route path="/galaxy-tab-s7fe/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-tab-s7fe/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7fe/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7fe/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7fe/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7fe/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7fe/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7fe/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7fe/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7fe/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7fe/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7fe/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7fe/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7fe/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7fe/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7fe/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7fe/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Galaxy Tab S6 Lite */}
        <Route path="/galaxy-tab-s6lite/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-tab-s6lite/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6lite/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6lite/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6lite/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6lite/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6lite/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6lite/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6lite/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6lite/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6lite/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6lite/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6lite/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6lite/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6lite/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6lite/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6lite/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Galaxy Tab S7 */}
        <Route path="/galaxy-tab-s7/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-tab-s7/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s7/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Galaxy Tab S6 */}
        <Route path="/galaxy-tab-s6/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-tab-s6/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-s6/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Galaxy Tab A9+ */}
        <Route path="/galaxy-tab-a9+/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-tab-a9+/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a9+/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a9+/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a9+/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a9+/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a9+/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a9+/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a9+/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a9+/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a9+/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a9+/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a9+/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a9+/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a9+/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a9+/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a9+/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Galaxy Tab A8 */}
        <Route path="/galaxy-tab-a8/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-tab-a8/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Galaxy Tab A8.4 */}
        <Route path="/galaxy-tab-a8.4/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-tab-a8.4/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8.4/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8.4/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8.4/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8.4/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8.4/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8.4/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8.4/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8.4/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8.4/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8.4/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8.4/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8.4/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8.4/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8.4/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a8.4/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Galaxy Tab A7 Lite */}
        <Route path="/galaxy-tab-a7lite/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-tab-a7lite/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7lite/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7lite/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7lite/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7lite/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7lite/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7lite/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7lite/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7lite/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7lite/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7lite/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7lite/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7lite/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7lite/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7lite/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7lite/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

        {/* Galaxy Tab A7 */}
        <Route path="/galaxy-tab-a7/select-issue" element={<IssueTablet selectedModel={selectedModel} setSelectedIssue={setSelectedIssue}/>} />
        <Route path="/galaxy-tab-a7/battery-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7/motherboard-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7/Camera-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7/screen-replacement/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7/faceid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7/back-panel/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7/mute-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7/heating-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7/microphone-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7/software-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7/speaker-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7/touchid/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7/power-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7/volume-button/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7/water-damage/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />
        <Route path="/galaxy-tab-a7/wifi-issue/price-details" element={<PriceDetailsTablet selectedBrand={selectedBrand} selectedModel={selectedModel} selectedIssue={selectedIssue} />} />

      </Routes>
    </Suspense>
    </BrowserRouter>
    </DeviceProvider>
    </>
  );
}

export default App