import { Helmet } from "react-helmet";
import fixifyLogo from "../Images/Fixify-logo.webp";

function SEO() {
  return (
    <Helmet>
      <title>Fixify - On-Demand Device & Appliance Services in Kolkata & Durgapur</title>
      <meta
        name="description"
        content="Fixify offers on-demand services for mobiles, laptops, tablets, earbuds & appliances in Kolkata & Durgapur. Free pickup, instant quotes & same-day service."
      />
      <meta
        name="keywords"
        content="device services Kolkata, laptop support Durgapur, tablet pickup, earbuds service, appliance care, same-day tech service, free pickup service, quick device support"
      />
      <meta name="author" content="Fixify" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      {/* Open Graph (OG) Tags for Social Media */}
      <meta
        property="og:title"
        content="On-Demand Services for Devices & Appliances | Fixify Kolkata & Durgapur"
      />
      <meta
        property="og:description"
        content="On-demand services for mobiles, laptops, earbuds, tablets & appliances. Free pickup, fast turnaround & more!"
      />
      <meta property="og:image" content={fixifyLogo} />
      <meta property="og:url" content="https://fixify.in/" />
      <meta property="og:type" content="website" />
    </Helmet>
  );
}

export default SEO;
